export default {
  "Drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف"])},
  "Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملوثات"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
  "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ضبط"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محادثة"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم التعريفي"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الطول"])},
  "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائرة العرض"])},
  "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الطول"])},
  "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائرة العرض"])},
  "Government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
  "All Governorates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المحافظات"])},
  "Add To Deleted List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الى قائمة الحذف"])},
  "View deleted list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اظهار قائمة الحذف"])},
  "Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود"])},
  "Region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة"])},
  "Coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإحداثيات"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة"])},
  "Refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
  "Images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور"])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
  "Confirm Deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد حذف"])},
  "Remove from Deleted List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ازل من قائمة الحذف"])},
  "are you sure you want to continue?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد إنك تريد المتابعة؟"])},
  "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيه"])},
  "Air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هواء"])},
  "Water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مياه"])},
  "Choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسليم"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر"])},
  "Boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود"])},
  "sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاعات"])},
  "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مركز/قسم"])},
  "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشياخة / الحي"])},
  "Sewage Coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغطية الصرف"])},
  "Industrial Zones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناطق الصناعية"])},
  "Governate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
  "Main Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجة الاساسية"])},
  "More Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من التفاصيل"])},
  "Export as an image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حفظ كصورة "])},
  "Percentage of Emission Rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة المئوية لمعدلات الانبعاثات"])},
  "Please select a pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر ملوث"])},
  "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مستخدم جديد"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "Egyptian Environmental Affairs Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهاز شئون البيئة"])},
  "Others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخرى"])},
  "Results per page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد النتائج بالصفحة"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
  "Danger Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى الخطورة"])},
  "All the types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الأنواع"])},
  "Highly Danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عالية الخطورة"])},
  "Medium Danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسطة الخطورة"])},
  "Low Danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قليلة الخطورة"])},
  "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكميه"])},
  "Factory Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المصنع"])},
  "All Departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الأقسام"])},
  "Failed to load data. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر تحميل البيانات. جرب لاحقاً مرةً أخري."])},
  "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العدد"])},
  "ton/year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طن/سنه"])},
  "Thousand ton/year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألف طن/سنه"])},
  "ton/month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طن/ شهر"])},
  "kWh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ك.و.س"])},
  "Mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميجا وات.ساعة"])},
  "MWatt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميجاوات"])},
  "gWh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيجا وات.ساعة"])},
  "kW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيلو وات"])},
  "Ton/Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طن/السنة"])},
  "Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملوثات"])},
  "Unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدة"])},
  "Other Unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدة أخري"])},
  "View by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
  "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة المئوية"])},
  "Absolute Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة المطلقة"])},
  "Edit Image Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل عنوان الصورة"])},
  "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد"])},
  "Unselected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير محدد"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
  "To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
  "Accepted Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة المقبولة"])},
  "Percent of Completeness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة اكتمال البيانات"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الانتظار"])},
  "Verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معتمد"])},
  "Rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرفوض"])},
  "Published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشور"])},
  "Cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملغي"])},
  "Request Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلب"])},
  "Verifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراجع"])},
  "Upload JSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع ملف JSON للمنشآت"])},
  "Choose File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الملف"])},
  "Other Requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات أخري"])},
  "Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشكلة"])},
  "From Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من تاريخ"])},
  "To Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى تاريخ"])},
  "Please Choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر"])},
  "Duplicate Names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسماء مكررة"])},
  "Missing Codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكود غير موجودة"])},
  "Missing Administrative Categorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد تقسيم إداري له"])},
  "Search Problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث المشكلات"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
  "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ع"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
  "Faddan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فدان"])},
  "Thousands Faddan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الف فدان"])},
  "Million Head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مليون رأس"])},
  "Total Agriculture Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحات مجاميع المحاصيل "])},
  "Total Number of animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعداد الثروة "])},
  "Animals and Poultry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الحيوانية والداجنة"])},
  "Total Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة الاراضي"])},
  "Value is too low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة منخفضة"])},
  "Value is too high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة مرتفعة"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
  "Enter message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل الرسالة"])},
  "Chat Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة الرسائل"])},
  "Satellite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القمر الصناعي"])},
  "Please choose a pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر الملوث"])},
  "Base Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخريطة"])},
  "draw layer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم"])},
  "drainage network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شبكة الصرف"])},
  "heatLayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخريطة الحرارية"])},
  "Gov border": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدود المحافظات"])},
  "Mrkaz border": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدود المراكز"])},
  "Mrkaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المركز"])},
  "Form has been saved!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حفظ الاستمارة بنجاح"])},
  "Failed to save form!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر حفظ الاستمارة"])},
  "Form has been submitted!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تسليم الاستمارة بنجاح"])},
  "Failed to submit form!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر تسليم الاستمارة"])},
  "Liquid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سائل"])},
  "Solid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلب"])},
  "Gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غاز"])},
  "Natural Gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غاز طبيعي"])},
  "Gas Cylinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسطوانات غاز"])},
  "Wood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خشب"])},
  "EEAA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهاز شئون البيئة"])},
  "Please enter username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك أدخل اسم المستخدم"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "Please enter password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" من فضلك أدخل كلمة المرور"])},
  "Change Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
  "Current Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
  "End Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التعديل"])},
  "My Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشآتي"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسائل"])},
  "Forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارات"])},
  "Facility User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم المنشأة"])},
  "View User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم التصفح"])},
  "Admin User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشرف"])},
  "Inquiries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعلامات"])},
  "Head Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظ الرئيسي"])},
  "Minister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوزير"])},
  "Your Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشآتك"])},
  "Organization Behavior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق المنظومة "])},
  "News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأخبار"])},
  "Parent Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشأة الرئيسية"])},
  "Sub-Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت الفرعية"])},
  "No subfacilities exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد منشآت فرعية"])},
  "Connection Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الوصلات"])},
  "Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستمارة"])},
  "Dumpsite Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقالب القمامة/المدافن"])},
  "Filtrate Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الرشيح"])},
  "Pollution Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل التلوث"])},
  "Please select a production line...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر خطاً للإنتاج..."])},
  "Garbage dumps and landfills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقالب القمامة والمدافن"])},
  "Number of similar units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الوحدات المشابهة"])},
  "Loading form, please wait...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم تحميل الاستمارة، رجاءً انتظر ..."])},
  "There is no production lines for the facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد خط انتاج لهذه المنشأة"])},
  "No form found.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد استمارة"])},
  "Error while retrieving form...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ خلال تحميل الاستمارة"])},
  "Retrieving facility data, please wait...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم تحميل بيانات المنشأة، رجاءً انتظر..."])},
  "Facility does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشأة غير موجودة"])},
  "Error retrieving facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في تحميل المنشأة"])},
  "Production Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة التصنيع"])},
  "Energy Consumption Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة استهلاك الطاقة"])},
  "Raw Materials Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة المواد الخام"])},
  "Air Pollution Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة تلوث الهواء"])},
  "Water Pollution Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة تلوث المياه"])},
  "Solid Pollution Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة تلوث المواد الصلبة"])},
  "Dangerous Pollutants Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة الملوثات الخطرة"])},
  "Water Usage Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة استهلاك المياه"])},
  "Final Waste Water status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوضع النهائي لمياه الصرف الصحي"])},
  "Cooling Water Pollution Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة تلوث مياه التبريد"])},
  "Waste Water Pollution Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة تلوث مياه الصرف"])},
  "Cooling Water Pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات مياه التبريد"])},
  "Waste Water Pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات مياه الصرف"])},
  "Air Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات الهواء"])},
  "No Pollutants Calculated For This facility Yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد ملوثات لهذه المنشأة حتى الآن"])},
  "No form found for this production line.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد استمارة لخط الانتاج"])},
  "Empty for now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فارغ الآن"])},
  "Emission Rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدلات الانبعاث"])},
  "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوزن"])},
  "gm/hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غم/الساعة"])},
  "Volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجم"])},
  "m^3/hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر مكعب/الساعة"])},
  "m^3/day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر^3/يوم"])},
  "Gas Speed inside Chimney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرعة الغاز في المدخنة"])},
  "m/sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر/الثانية"])},
  "Temperature of the Chimney from the inside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الحرارة المدخنة من الداخل"])},
  "Celsius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة مئوية"])},
  "Chimney Specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواصفات المدخنة"])},
  "Chimney Diameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطر المدخنة الداخلي"])},
  "Tank Diameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطر التنك"])},
  "Tank Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارتفاع التنك"])},
  "Meter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر"])},
  "meter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر"])},
  "Centimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنتيمتر"])},
  "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سم"])},
  "inch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوصة"])},
  "Micrometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مايكرومتر"])},
  "Millimeter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميللي متر"])},
  "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مم"])},
  "m^2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر مربع"])},
  "millionm3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مليون متر مكعب"])},
  "thousandlitre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألف لتر"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات"])},
  "Chimney Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارتفاع المدخنة"])},
  "Operating System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظام التشغيل"])},
  "Yearly Working Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات التشغيل السنوية"])},
  "Control Unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدة التحكم"])},
  "Pollutants Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قياسات الملوثات"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة"])},
  "Primary Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات الرئيسية"])},
  "Energy Sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر الطاقة"])},
  "Other Energy Sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر الطاقة الأخرى"])},
  "Raw Material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المادة الخام"])},
  "Raw Materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الخام"])},
  "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "Packaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعبئة"])},
  "Amount (Ton/Year)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(الكمية (طن/السنة"])},
  "Dangerous?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطر؟"])},
  "Commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التجاري"])},
  "Scientific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلمي"])},
  "Special Storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخزين الخاص"])},
  "Transportation inside Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقل إلى داخل المنشأة"])},
  "Safety Procedures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات الأمان"])},
  "Emergency Procedures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراءات الطوارئ"])},
  "Transportation Outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقل إلى خارج المنشأة"])},
  "Disposal Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق التخلص"])},
  "Add Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف سجلاً"])},
  "Please select both pollutant type and pollutant name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجاءً اختر نوع واسم الملوث"])},
  "Transfer Officer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضابط النفل"])},
  "Recycled Waste Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية النفايات المكررة"])},
  "Final Disposal of Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخلص النهائي من النفايات"])},
  "Place of Disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان التخلص"])},
  "It shouldn't be empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا ينبغي أن يكون فارغاً"])},
  "Main Water Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصدر المياه الأساسي"])},
  "Is there other water source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مصدر المياه الفرعي"])},
  "Secondary Water Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصدر المياه الفرعي"])},
  "Selected Unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدة المختارة"])},
  "Water used in Production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المياه المستخدمة في التصنيع"])},
  "Water used in Boilers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المياه المستخدمة في الغلايات"])},
  "Human usage of Water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستهلاك الأدمي للمياه"])},
  "Water used for Cooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المياه المستخدمة في التبريد"])},
  "Total Water Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستخدام الكلى للمياه"])},
  "Other Water Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستخدام الأخر للمياه"])},
  "Waste Water Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع النفايات المائية"])},
  "Applies Sewage Treatment?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يقوم بمعالجة الصرف"])},
  "Has a Dedicated Station for Facility?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد محطة خاصة بالمصنع"])},
  "Is industrial sewage mixed with sanitation before drainage?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يخلط الصرف الصناعي مع الصحي قبل الصرف"])},
  "Sewage Treatment Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع معالجة الصرف"])},
  "Treated Water Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المياه المعالجة"])},
  "Waste Water after Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفايات المائية بعد المعالجة"])},
  "Water Waste Draining Place Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع مكان التخلص من النفايات المائية"])},
  "Draining Place Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المصرف"])},
  "Draining Place Name Industrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المصرف الصناعي"])},
  "Draining Place Name Hygienic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المصرف الصحي"])},
  "Water Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استهلاك المياه"])},
  "Water Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معالجة المياه"])},
  "Top Facilities for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر المنشآت تلويثاُ"])},
  "Top Sectors for Emissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر القطاعات اصدارا للملوثات"])},
  "Top Sectors for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر القطاعات تلويثاُ"])},
  "Top 80% Sectors for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكثر 80% من القطاعات تلويثاُ"])},
  "Choose a report to display here...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر تقريراً للعرض هنا..."])},
  "Empty Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير فارغ"])},
  "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم"])},
  "Week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسبوع"])},
  "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر"])},
  "Three Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثلاث شهور"])},
  "Six Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستة شهور"])},
  "Bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريطي"])},
  "HBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفقي"])},
  "Pie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائري"])},
  "Name can't be empty. ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تحديد الاسم"])},
  "Unit must be provided. ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تحديد الوحدة"])},
  "The value must be a number. ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة يجب أن تكون رقماً"])},
  "Number must be greater than 0. ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم يجب أن يكون أكبر من صفر"])},
  "Item already exists.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنصر موجود بالفعل"])},
  "The field shouldn't be empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن أن يكون المدخل فارغاً"])},
  "The value in the field isn't from the listed options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة المدخلة ليست من قائمة الخيارات"])},
  "Search by address...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث بالعنوان"])},
  "Loading...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جارى التحميل"])},
  "All Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الملوثات"])},
  "Error fetching pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ في تحميل الملوثات"])},
  "View Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعرض التقرير"])},
  "View Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعرض الخطء"])},
  "Replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استبدال"])},
  "New User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستخدم جديد"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
  "Amount of Unrecycled Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المخلفات التي لم يتم تدويرها"])},
  "Inputs and Outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدخلات و المخرجات"])},
  "Chimney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدخنة"])},
  "Water Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات المياه"])},
  "Water Draining Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات المياه من الصرف"])},
  "Water Industry Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات المياه من الصناعة"])},
  "Air Fuel Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات الهواء من الوقود"])},
  "Air Industry Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات الهواء في الصناعة"])},
  "Air Measured Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات الهواء المقاسة"])},
  "Surrounding Area Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر أخري لملوثات الهواء"])},
  "Information is complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تم استكمال البيانات للعام الحالي"])},
  "Information is not entered for this year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم ادخال البيانات للعام الحالي"])},
  "Information is Incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تسليم الاستمارة بعد"])},
  "waiting verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ادخال البيانات للمراجعة"])},
  "Print Environmental Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة"])},
  "Report An Issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابلاغ عن مشكلة"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدة"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدة"])},
  "Add a Production Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة خط انتاج"])},
  "Add a new chimney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة مدخنة جديدة"])},
  "Number of Shifts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الورديات"])},
  "Number of Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الورديات"])},
  "Number of Shifts per Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الورديات في اليوم"])},
  "Number of Workers per Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد العمال في الوردية"])},
  "Select a Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر السنة"])},
  "Show Past Years Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اظهار بيانات سنوات سابقة"])},
  "Compare Two Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقارنة سنتين"])},
  "Show Changing Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اظهار معدل التغيير"])},
  "Back To Current Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة للسنة الحالية"])},
  "SolidWaste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفايات الصلبة"])},
  "Power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طاقة"])},
  "Dangerous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطر"])},
  "Production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنتاجي"])},
  "Oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زيت"])},
  "Oil Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الزيت"])},
  "Heating Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة حرارة التسخين"])},
  "Condition on Exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة عند خروجه"])},
  "Comparison Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وجه المقارنة"])},
  "Preview as Another User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشاهدة نافذة مستخدم"])},
  "Change User Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير نوع مستخدم"])},
  "Change a current User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير مستخدم حالي"])},
  "Delete a current User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء مستخدم حالي"])},
  "Change a current Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير منشأة حالية"])},
  "createFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء منشأة جديدة"])},
  "editFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير منشأة حالية"])},
  "Create a New Pollutant Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء نوع ملوث جديد"])},
  "createCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء نوع ملوث جديد"])},
  "Change a current Pollutant Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير نوع ملوث الحالي"])},
  "createPollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء ملوث جديد"])},
  "Change a current Pollutant Item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير ملوث الحالي"])},
  "You can from this portal:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك من خلال هذه البوابة:"])},
  "Error while Creating User...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ظهرت مشكلة خلال إنشاء مستخدم جديد"])},
  "Upload Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل منشآت"])},
  "Upload Water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل المياه"])},
  "Upload Solid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل الصلب"])},
  "Factories Per Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت بالقطاع"])},
  "Top 5 Pollutants Per Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعلى ملوثات بالقطاع"])},
  "Top 5 Pollutants Per Sector Sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير أعلى ملوثات بالقطاع"])},
  "Top 5 Pollutants Per Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعلى ملوثات بالمحافظة"])},
  "per sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالقطاع"])},
  "Number of results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد النتائج"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
  "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
  "Maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخرائط"])},
  "SIC Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود الصناعي"])},
  "Create a New Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء قطاع صناعي جديد"])},
  "Top Five Air Pollution Per Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهم ملوثات هواء في القطاع"])},
  "Street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشارع"])},
  "All Industrial Zones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كل المناطق الصناعية"])},
  "Review current reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة التقارير الحالية "])},
  "Review the facilities map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة خريطة المنشآت"])},
  "Sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
  "Cairo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القاهرة"])},
  "Alexandria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسكندرية"])},
  "Port Said": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بورسعيد"])},
  "Suez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السويس"])},
  "Damietta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دمياط"])},
  "Dakahliya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقهلية"])},
  "Sharqia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشرقية"])},
  "Qalyubia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القليوبية"])},
  "Kafr El Sheikh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كفر الشيخ"])},
  "Gharbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغربية"])},
  "Monufia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنوفية"])},
  "Beheira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحيرة"])},
  "Ismailia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسماعيلية"])},
  "Giza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجيزة"])},
  "Beni Suef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بنى سويف"])},
  "Faiyum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفيوم"])},
  "Minya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنيا"])},
  "Assiut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسيوط"])},
  "Sohag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوهاج"])},
  "Qena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قنا"])},
  "Aswan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسوان"])},
  "Luxor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقصر"])},
  "Red Sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحر الأحمر"])},
  "El Wadi El Gedid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوادي الجديد"])},
  "Matrouh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطروح"])},
  "North Sinai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شمال سيناء"])},
  "South Sinai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنوب سيناء"])},
  "Facilities with no info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشآت بلا بيانات"])},
  "All Cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المدن"])},
  "Please Choose a problem first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك أختر نوع المشكلة أولا"])},
  "Tiny Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشآت متناهية الصغر"])},
  "All Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المنشآت"])},
  "Important Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشآت ذات بيانات"])},
  "Choose Type of Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مستوي التفاصيل"])},
  "Factories Per Departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت بالأقسام الادارية"])},
  "Facility Image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة المنشأة"])},
  "No image for the facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد صورة للمنشأة"])},
  "Heaters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سخانات"])},
  "Extra Elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناصر اضافية"])},
  "Boiler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غلاية"])},
  "Boiler Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الغلاية"])},
  "Furnace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرن"])},
  "Furnaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفران"])},
  "Inventories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخازن"])},
  "Tank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنك"])},
  "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلاهما"])},
  "addfacilitydetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تفاصيل المنشأة"])},
  "editfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل المنشأة"])},
  "removeuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف مستخدم"])},
  "modifyuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مستخدم"])},
  "removesector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف قطاع"])},
  "viewreports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التقارير"])},
  "addsector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة قطاع"])},
  "viewvisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض عدد الزيارات"])},
  "searchmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث الخرائط"])},
  "removesubsector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف قطاع فرعي"])},
  "modifypollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل ملوث"])},
  "allpollutanttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع انواع الملوثات"])},
  "searchaggregatemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث المجمع للخرائط"])},
  "removepollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف ملوث"])},
  "assignuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين المستخدم"])},
  "allgovernorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المحافظات"])},
  "addlaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة قانون"])},
  "verifydata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من البيانات"])},
  "removecontrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إزالة تحكم"])},
  "viewfacilitydetailsfornonowners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض تفاصيل المنشأة لغير المالكين"])},
  "privilegescol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامتيازات"])},
  "removefacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المنشأة"])},
  "addcontrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تحكم"])},
  "uploaddata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع البيانات"])},
  "approvedatachanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموافقة على تغييرات البيانات"])},
  "allfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المنشآت"])},
  "editcontrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل التحكمات"])},
  "viewfacilitydetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض تفاصيل المنشأة"])},
  "editsubsector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل القطاع الفرعي"])},
  "approveuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول مستخدم"])},
  "allmrkaz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع المراكز"])},
  "editfacilitydetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل تفاصيل المنشأة"])},
  "editsector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل القطاع"])},
  "viewsupervisormap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خريطة المشرف"])},
  "viewairmeasurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض قياسات الهواء "])},
  "removelaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف قانون"])},
  "verifierreports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير المراجع"])},
  "editprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل عملية"])},
  "addpollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة ملوث"])},
  "addfacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة منشأة"])},
  "adduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مستخدم"])},
  "unassignuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء تعيين المستخدم"])},
  "addsubmitformdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تاريخ تقديم النموذج"])},
  "removeprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف عملية"])},
  "addsubsector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة القطاع الفرعي"])},
  "addprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عملية"])},
  "Old Industrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الصناعي القديم"])},
  "Utilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الخدمي"])},
  "Number of Animals Slaughtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحيوانات المذبوحة"])},
  "Show pollution weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إظهار أحمال التلوث"])},
  "Industry Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القطاع"])},
  "Industrial Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت الصناعية"])},
  "Factories with dangerous sewage dumping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشآت الصناعية ذات الصرف عالي الخطورة"])},
  "Factories with hot/cool ventilation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصانع التي يوجد بها دورة تبريد/تسخين مفتوحة"])},
  "Top Servicing Sectors for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكثر القطاعات الخدمية إصدارا للملوثات"])},
  "Top Five Pollution Per Servicing Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحصائيات الملوثات بالقطاع الخدمي"])},
  "Factories Per Servicing Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصائيات مصادر الملوثات بالقطاعات الخدمية "])},
  "Top Servicing Facilities for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعلي مصادر الملوثات بالقطاع الخدمي"])},
  "Mixing Stations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محطات الخلط"])},
  "Sewage Detailed Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناطق المخدومة والمحرومة عليها محطات الصرف الصحي"])},
  "Areas of sewage treatment plants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناطق محطات الصرف الصحي"])},
  "Water treatment plants and water intakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محطات معالجة مياه الشرب ومآخذ المياه"])},
  "Top Agricultural Facilities for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعلي مصادر الملوثات بالقطاع الزراعي"])},
  "Ventilation Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع دورة التهوية"])},
  "Agricultural Sewage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف الزراعي"])},
  "Top Sewage station Sectors for Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكثر قطاعات محطات الصرف اصدارا للملوثات"])},
  "Losses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخسائر"])},
  "Output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخرجات"])},
  "Mazout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مازوت"])},
  "Solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سولار"])},
  "Zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة"])},
  "Diesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سولار"])},
  "diesel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ديزل"])},
  "Coal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فحم"])},
  "Other Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع آخر"])},
  "Used Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقود المستخدم"])},
  "Percentage of Used Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الوقود المستخدم"])},
  "Ammount of Fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الوقود"])},
  "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض"])},
  "reject and reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض واعادة فتح"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
  "Semi-Sector parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الصناعي المجمع"])},
  "Sub-Sector parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الزراعي الفرعي"])},
  "Sub-Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطاع الزراعي"])},
  "English Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالانجليزية"])},
  "Arabic Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالعربية"])},
  "Season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموسم"])},
  "Summer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صيفية"])},
  "Winter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شتوية"])},
  "Nile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نيلي"])},
  "Perennial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معمر"])},
  "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موافق"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر"])},
  "Are you sure you want to delete this crop?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل أنت متأكد من حذف هذا المحصول؟"])},
  "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "No message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد رسالة"])},
  "Does it has Inventories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه مخازن"])},
  "Does it has Water Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه ملوثات مياه "])},
  "Does it has Air Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديه ملوثات هواء"])},
  "Does it has Painting Processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد عمليات دهان"])},
  "Does it has Welding Operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد عمليات لحام"])},
  "Does it has Electroplating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد طلاء بالكهرباء"])},
  "Does it has Residential Community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد تجمع سكني"])},
  "Is there a measurement opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد فتحة قياس"])},
  "Is it suitable for measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل الفتحة مطابقة للمواصفات"])},
  "Suggested date for measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميعاد مقترح للقياس"])},
  "Opening Accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امكانية الوصول للفتحة"])},
  "The Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبب"])},
  "Add an Inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مخزن"])},
  "Add a Tank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة تنك"])},
  "Add Unit Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة بيانات وحدة"])},
  "Delete User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح مستخدم"])},
  "Edit User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مستخدم"])},
  "Failed to upload file with this extension, only allowed is xls or xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر  رفع الملف بهذا الملحق, مسموح فقط بxls or xlsx  "])},
  "Failed to upload file with this extension, only allowed is zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر  رفع الملف بهذا الملحق, مسموح فقط بzip  "])},
  "Failed to upload file with this extension, only allowed is pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر  رفع الملف بهذا الملحق, مسموح فقط بpdf  "])},
  "Reference Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم المرجعي"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "Number of Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الساعات"])},
  "Number of Hours per Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الساعات في اليوم"])},
  "Number of Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الايام"])},
  "Number of Days per Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الايام في السنة"])},
  "Number of Days per Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الايام في الشهر"])},
  "Number of Months per Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الشهور في السنة"])},
  "Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعة"])},
  "Boiler Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعة الغلاية"])},
  "Water Boiler Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعة غلاية الماء"])},
  "Oil Boiler Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعة غلاية الزيت"])},
  "Inventory Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة المخزن"])},
  "Site Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة الموقع"])},
  "Pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط"])},
  "Gravity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجاذبية"])},
  "Pressure inside tank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط داخل الخزان"])},
  "HoursPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعة في اليوم"])},
  "DaysPerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيام في السنة"])},
  "ug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ميكروجرام"])},
  "mg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجم"])},
  "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جرام"])},
  "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كجم"])},
  "Kilo Gram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كجم"])},
  "ton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طن"])},
  "Ton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طن"])},
  "Kilo Meter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم"])},
  "N/m^2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ن/م^2"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
  "Calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب"])},
  "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "Add a Boiler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة غلاية"])},
  "Amount of water meter cube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كمية المياه بالمتر المكعب"])},
  "Pollutant source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصدر الملوث"])},
  "Break down chart on pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير تفصيلي للملوثات"])},
  "Please Choose a Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مصدرا"])},
  " Please refresh the page to see the changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تحديث الصفحة لرؤية التغييرات  "])},
  "Successfully updated request data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".تم تحديث البيانات بنجاح"])},
  "Modification requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم مراجعة البيانات ومطلوب تعديلات"])},
  "Information is verified for this year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم قبول ومراجعة البيانات"])},
  "No Attachements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مرفقات"])},
  "Industrial Process Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف العملية الصناعة"])},
  "Fuel Loads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احمال الوقود"])},
  "Manfacturing Loads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احمال الصناعة"])},
  "Raw Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخري"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
  "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأولي"])},
  "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأخيرة"])},
  "Percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة مئوية"])},
  "Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التركيز"])},
  "Number of visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الزيارات"])},
  "Percentage of revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة المراجعة"])},
  "Doughnut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائري مفرغ"])},
  "Total Pollutant Loads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي أحمال الملوثات"])},
  "Total pollutant load percents per government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نسبة إجمالي احمال الملوثات علي مستوي المحافظة"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
  "Reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت المراجعة"])},
  "Update Rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث التقييم"])},
  "Rating is updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحديث التقييم"])},
  "Unable to update rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر تحديث التقييم"])},
  "Facility Is Submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تسليم الاستمارة"])},
  "New Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمارة جديدة"])},
  "Extra Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج إضافية"])},
  "Display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العرض"])},
  "Solid Waste Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفايات الصلبة"])},
  "Percentage of data entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبه الادخال"])},
  "Percentage of validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبه التأكيد"])},
  "Number of governments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المحافظات"])},
  "Top Five Pollutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعلي خمسه ملوثات"])},
  "Crops/Livest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاصيل/ماشية ودواجن"])},
  "Area(Feddan)/Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة(فدان)/ أعداد"])},
  "Area(Feddan)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة(فدان)"])},
  "Feddan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فدان"])},
  "Entered facilities per government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبه الادخال للمحافظات"])},
  "This report shows number of pollution sources per government": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعرض هذا التقرير أعداد مصادر التلوث في كل محافظة"])},
  "This report shows highest sub-secotrs for the selected pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعرض هذا التقرير نسبة أعلي قطاعات فرعية تصدر الملوث الذي تم اختياره"])},
  "This report shows top Industrial Sectors for pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعرض هذا التقرير نسبة أعلي قطاعات فرعية تصدر الملوث الذي تم اختياره"])},
  "This report shows highest producer for the selected pollutant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعرض هذا التقرير أكثر المصادر التي تصدر الملوث الذي تم اختياره"])},
  "succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت العملية بنجاح"])},
  "succeeded in adding User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم انشاء مستخدم جديد بنجاح"])},
  "you don't have permission or username already exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك صلاحية أو اسم المستخدم غير متوفر"])},
  "You can't use collective search when providing ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن استخدم البحث المجمع و الرقم التعريفي معا"])},
  "Please Provide a Year when choosing Agriculture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر السنة لعرض القطاع الزراعي"])},
  "Please Provide a Year when selecting a facility status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حالة اختيار حالة للمنشآت بالرجاء تحديد السنة"])},
  "Searching without choosing Crop is very slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البحث دون اختيار محصول سيستغرق وقت طويل"])},
  "Please Provide a Sector when choosing Agriculture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء اختيار القطاع الزراعي"])},
  "This report shows The top five pollutants per sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعرض هذا التقرير تقسيمه الملوثات بالقطاعات الفرعية"])},
  "Search by Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث بالمنتج"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتج"])},
  "The Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج"])},
  "This report shows the facilities producing certain product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعرض هذا التقرير المنشآت التي تقوم بإنتاج منج معين"])},
  "Enter your comment on the following form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل تعليقك"])},
  "Please choose a Name and Primary Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى اختيار الاسم والنوع الأساسي"])},
  "failed to save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر عملية الحفظ"])},
  "Add Boilers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة غلايات"])},
  "Add Chimneys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مداخن"])},
  "Please provide a Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى اختيار المنتج"])},
  "PDFS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات PDF"])},
  "Clear Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح النتائج"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
  "Send Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال تعليق"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "Help Desk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتب المساعدة"])},
  "Succeeded in deleting attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم حذف الملف بنجاح."])},
  "Error in deleting attachment. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر عملية حذف الملف."])},
  "Try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاول مجدداً"])},
  "Error while sending message...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ظهرت مشكلة خلال الإرسال"])},
  "Attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارفاق"])},
  "failed to save Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر حفظ المنشأة"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "Form Filling Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ ملء الاستمارة"])},
  "Form Submission Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ تسليم الاستمارة"])},
  "Electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كهرباء"])},
  "MSDS Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MSDS للمواد المستخدمة"])},
  "Hazardous Waste Tracking Certificate For Each Shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة تتبع المخلفات الخطرة لكل شحنة"])},
  "Facility Images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور المنشاة"])},
  "Engineering Drawings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسومات تصميمية وهندسية"])},
  "Enter a Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسم"])},
  "Number of Equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعداد المعدات"])},
  "Stored Material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المادة المخزنة"])},
  "Liquid Organic Material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المادة العضوية السائلة"])},
  "Is it Crude Oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هو زيت خام"])},
  "Stored Solid Material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الصلبة المخزنة"])},
  "Average Particle Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط حجم الجزيئات"])},
  "Is it Hazardous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هي خطرة"])},
  "Hazardous Wastes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخلفات الخطرة"])},
  "Ceiling Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع السقف"])},
  "Tank Ceiling Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع سقف التنك"])},
  "Amount Used Per Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية المستخدمة في السنة"])},
  "Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الارتفاع"])},
  "Diameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القطر"])},
  "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الحرارة"])},
  "Stored Height inside Tank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طول المخزون في الخزان"])},
  "Annual net productivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صافي الإنتاجية السنوية"])},
  "Tank Surface Condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة السطح الخارجي للخزان"])},
  "Static Ceiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سقف ثابت"])},
  "Dynamic Ceiling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سقف عائم"])},
  "Amount Stored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية مخزون"])},
  "Amount of Treated Drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الصرف المعالج"])},
  "Industrial Drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صرف صناعي "])},
  "Sanitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صرف صحي"])},
  "Add a New Production Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خط إنتاج جديد"])},
  "Secondary Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات الثانوية"])},
  "Product Classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف المنتج"])},
  "Number of classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفئات"])},
  "max value of class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقصى قيمة للفئة"])},
  "min value of class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقل فئة"])},
  "Final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهائي"])},
  "Intermediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيط"])},
  "You can't make the max value less than or equal the min value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ان تكون أكبر قيمة للملوث أعلى من أقل قيمة للملوث"])},
  "You need to specify the max value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تحديد أكبر قيمة"])},
  "You need to specify the min value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تحديد أصغر قيمة"])},
  "values of classes are not ordered accendingly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ٌقيم الفئات غير مرتبة من الأصغر للأكبر"])},
  "Your previous request is still processing, please wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبك السابق قيد التنفيذ، من فضلك انتظر قليلا"])},
  "Production Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الإنتاج"])},
  "Production Rate Per Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الإنتاج السنوي"])},
  "Production Rate (Production Quantity)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل الإنتاج (كمية الإنتاج)"])},
  "Production Pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمط الإنتاج"])},
  "Batch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باتش"])},
  "Seasonal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موسمي"])},
  "Number per Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عددهم في اليوم"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة"])},
  "Continuous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستمر"])},
  "Permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائم"])},
  "Product Destiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصير المنتج "])},
  "Waste Destiny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصير المخلف"])},
  "Waste Collecting Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشركة المسئولة عن تجميع المخلفات"])},
  "Waste Collecting Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" معدل تجميع المخلفات"])},
  "Its Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوعه"])},
  "Industrial Drainage Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علام تصرف مياه الصرف الصناعي "])},
  "Sewerage System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شبكة صرف"])},
  "Nile River": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النيل"])},
  "Sea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحر"])},
  "Please Explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التوضيح"])},
  "Pollutants That Must Be Removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملوثات التي  يجب إزالتها"])},
  "Solid Materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد الصلبة"])},
  "Solid Wastes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخلفات الصلبة"])},
  "Air Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلوث الهواء"])},
  "Water Pollution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلوث المياه"])},
  "Air Pollution Control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحكم قي تلوث الهواء"])},
  "Water Pollution Control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحكم قي تلوث المياه"])},
  "Type of Pollution Control Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع جهاز التحكم في التلوث"])},
  "The Unit on Which The Controller is Installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدة المركب عليها جهاز التحكم"])},
  "The Pollutant That Will Be Removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملوث الذي سيتم إزالته"])},
  "Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكفاءة"])},
  "What is the Treatment Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي طريقة المعالجة"])},
  "Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم بيعه"])},
  "Used in Another Industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يدخل في صناعة أخري"])},
  "Recycled in same facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعاد تدويره في نفس المصنع"])},
  "Given to a contractor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يأخذه متعهد"])},
  "Date Uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإرفاق"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنزيل"])},
  "There are no boilers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد غلايات"])},
  "There are no production lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد خطوط انتاج"])},
  "Second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانية"])},
  "Minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقيقة"])},
  "Hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعة"])},
  "Cubic Meter (M^3)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر مكعب"])},
  "mm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مم مربع"])},
  "cm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سم مربع"])},
  "m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر مربع"])},
  "km2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم مربع"])},
  "mm3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مم مكعب"])},
  "cm3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سم مكعب"])},
  "Liters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دسم مكعب (لتر)"])},
  "m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متر مكعب"])},
  "km3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم مكعب"])},
  "L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دسم مكعب (لتر)"])},
  "Manual Entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدخال يدوي"])},
  "Facility Name Manual Entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدخال اسم المنشأة يدوياً"])},
  "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوح"])},
  "Underprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد التنفيذ"])},
  "Product Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المنتج"])},
  "Waste From Industrial Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المخلف الناتج من العملية الصناعية"])},
  "Required Conversions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويلات مطلوبة"])},
  "Industrial Drainage For Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف الصناعي للخط"])},
  "Industrial Drainage For Facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف الصناعي للمصنع"])},
  "Drainage amount from the line (m3 / month)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الصرف من الخط (م3/شهر)"])},
  "Drainage amount from the facility (m3 / month)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الصرف من المنشأة (م3/شهر)"])},
  "Water usage for this line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استهلاك المياه لهذا الخط"])},
  "Water usage for this facility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الاستخدام"])},
  "Drainage Usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علام تصرف مياه الصرف المجمعة"])},
  "Drainage Usage Industrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علام تصرف مياه الصرف الصناعي"])},
  "Drainage Usage Hygienic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علام تصرف مياه الصرف الصحي"])},
  "Wells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيارة"])},
  "Agricultural Drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصرف زراعي"])},
  "Recycled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تدوير"])},
  "Recycling Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة اعادة التدوير"])},
  "Are there treatment units for line?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدات معالجة خاصة بالخط؟"])},
  "Is there a central treatment unit for facility?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد محطة معالجة مركزية للمصنع؟"])},
  "Way of treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة المعالجة"])},
  "You are about to change the Super Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انت على وشك تغيير القطاع الرئيسي"])},
  "Please Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء التأكيد"])},
  "Bakery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخبز"])},
  "Piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطعة"])},
  "Box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علبة"])},
  "Unit Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وزن الوحدة"])},
  "Workshop Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الورشة"])},
  "Painting Processes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات الدهان"])},
  "Welding Operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات لحام"])},
  "Electroplating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلاء بالكهرباء"])},
  "WorkShop Industrial Drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف الصناعي للورشة"])},
  "Paint On": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم دهان"])},
  "Cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيارات"])},
  "Trucks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاحنات"])},
  "Wood Furniture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أثاث خشبي"])},
  "Metal Furniture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أثاث معدني"])},
  "Number of painted cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد السيارات التي يتم دهانا"])},
  "Number of painted trucks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الشاحنات التي يتم دهانا"])},
  "Amount of paint used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الدهان المستخدم"])},
  "Painted total surface area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساحة السطحية الكلية المدهنة"])},
  "Welding Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع اللحام"])},
  "Electroplating Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلاء"])},
  "Amount of welding used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية اللحام المستخدم"])},
  "Avarage Ampere for Electroplating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط الأمبير المستخدم في الطلاء"])},
  "Total Electroplating Hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي عدد ساعات الطلاء"])},
  "Drainage used for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم الصرف على"])},
  "Amount of Drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الصرف الصناعي"])},
  "Production Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الإنتاج"])},
  "Amount of produced energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الطاقة المنتجة"])},
  "Type of generated wastes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المخلفات الناتجة"])},
  "Means of garbage disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية وأماكن التخلص من القمامة"])},
  "Water Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاع المياه"])},
  "Other notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات أخري"])},
  "Amount of natural gas consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية استهلاك غاز الطبيعي"])},
  "Number of gas cylinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد أسطوانات الغاز"])},
  "Air waste weights caused by sewage treatment plants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحمال تلوث الهواء الناتج عن محطات الصرف الصحي"])},
  "Gas cylinder capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعة أسطوانة الغاز"])},
  "Gas type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الغاز"])},
  "Sewage Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معالجة الصرف"])},
  "Number of caol bags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد شكائر الفحم"])},
  "Coal bag weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وزن شكارة الفحم"])},
  "Ignition method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية الإشعال"])},
  "Ignition starts over coal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشعال يبدأ فوق الفحم"])},
  "Ignition starts under coal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشعال يبدأ تحت الفحم"])},
  "Butane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيوتان"])},
  "Propane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بروبان"])},
  "Under Maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحت الإنشاء"])},
  "Amount of Mazout Used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المازوت المستخدم"])},
  "Amount of Solar Used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية سولار المستخدم"])},
  "Amount of Wood Used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الخشب المستخدمة"])},
  "There are no chimneys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مداخن"])},
  "Electrical energy used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة الكهربية المستخدمة"])},
  "Electrical energy used in kWh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة الكهربية المستخدمة بالكيلو وات ساعة"])},
  "Pound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنيه"])},
  "Pound monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنيه شهرياً"])},
  "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون"])},
  "Our developers are hard at work updating your system. Please wait try again later, or go back to the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناسف للإزعاج, الصفحة لازالت قيد الإنشاء. يمكنك المعاودة لاحقا او الرجوع الى"])},
  "Hard chromium electroplating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كروم صلب كثيف"])},
  "Decorative electroplating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كروم خفيف (لغرض التلوين)"])},
  "Chromic acid anodizing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمض الكروميك"])},
  "Bread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خبز بلدي"])},
  "Wholemeal bread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خبز افرنجي"])},
  "Cakes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حلويات غربي"])},
  "Biscuits & Breakfast cereals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بسكويت او ما شابه"])},
  "Furnaces Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الافران"])},
  "Please add an appropriate number of Furnaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" من فضلك ادخل عدد الافران اولا"])},
  "Chimneys Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المداخن"])},
  "Inventories Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المخازن"])},
  "Tanks Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد التنكات"])},
  "Subjects Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المواد"])},
  "Subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد"])},
  "Connections Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الوصلات"])},
  "Storage Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المخزن"])},
  "What is the method of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي طريقة"])},
  "Transporting solid materials between equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نقل المواد الصلبة بين المعدات"])},
  "Transportation Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة النقل"])},
  "Transported Material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواد المنقولة"])},
  "Equipment Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طول المعدة"])},
  "Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة"])},
  "Other Comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات أخري"])},
  "please enter the year in correct format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك ادخل السنة مكونة من اربعة ارقام"])},
  "please enter the phone number as mobile 01xxxxxxxxxx or landline with the governorate code 0xxxxxxxxx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك ادخل رقم الهاتف مثل 01xxxxxxxxx أو هاتف ارضي مسبوق بكود المحافظة دون مسافات"])},
  "Does water come out as part of the product?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل المياه تخرج كجزء من المنتج؟"])},
  "Production line percent of industrial drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الاستهلاك من الصرف الصناعي"])},
  "Sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عفواً"])},
  "No search results found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على نتائج بحث"])},
  "Light Rust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصدأ الخفيف"])},
  "Dense Rust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصدأ الكثيف"])},
  "Concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخرسانة"])},
  "Chemical Compound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المركب الكيمائي"])},
  "Connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصلات"])},
  "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المادة"])},
  "Connection Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوصلة"])},
  "Atmospheric Pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط الجوي"])},
  "Vap. Pressure at Stored Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضغط البخار عند درجة الحرارة المخزنة"])},
  "Molecular Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوزن الجزيئي"])},
  "Maximum Ambient Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الحرارة المحيطة القصوى"])},
  "Minimum Ambient Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة الحرارة المحيطة الدنيا"])},
  "Vap. Pressure at Max Ambient Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضغط البخار عند درجة الحرارة المحيطة القصوى"])},
  "Vap. Pressure at Min Ambient Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضغط البخار عند الحد الأدنى لدرجة الحرارة المحيطة"])},
  "Wind Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرعة الرياح"])},
  "Vap. Pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضغط البخار"])},
  "Density of The Liquid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كثافة السائل"])},
  "Indoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغلق"])},
  "Outdoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مفتوح"])},
  "3 wall area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 حوائط"])},
  "Maximum Size 30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجم الأقصى 30"])},
  "Maximum Size 15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجم الأقصى 15"])},
  "Maximum Size 10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجم الأقصى 10"])},
  "Maximum Size 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجم الأقصى 5"])},
  "Maximum Size 2.5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجم الأقصى 2.5"])},
  "Material Humidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رطوبة المخزون"])},
  "Average Wind Speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط سرعة الرياح"])},
  "Combustion Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل حرق الوقود"])},
  "Stove Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الموقد"])},
  "Sulfur Content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محتوى الكبريت"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
  "SubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة الفرعية"])},
  "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية"])},
  "SubProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية الفرعية"])},
  "Rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])},
  "Percent of Completed Visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الزيارات المكتملة"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة فتح"])},
  "Reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة فتح"])},
  "Revisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة زيارة"])},
  "Visitor Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الزائر"])},
  "Num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
  "No Rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم التقييم"])},
  "No Visits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يقم بزيارات"])},
  "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم بنجاح"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم بنجاح"])},
  "Fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذرت العملية"])},
  "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذرت العملية"])},
  "Verifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري التحقق"])},
  "The facility has been added to deleted list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم اضافة المنشأة الى قائمة الحذف"])},
  "The facility has been removed from deleted list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ازالة المنشأة من قائمة الحذف"])},
  "Some facilities could not be deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر حذف بعض المنشآت"])},
  "Form Filler Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق مالئ الاستمارة"])},
  "Verifier Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق المتمم"])},
  "Disposal Site Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع موقع التخلص"])},
  "Transfer Station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محطة مناولة"])},
  "Controlled Dump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقلب محكوم"])},
  "Uncontrolled Dump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقلب غير محكوم"])},
  "Landfill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدفن صحي"])},
  "Incinerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محرقة"])},
  "Incinerators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محارق"])},
  "Organic Fertilizer Factory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصنع أسمدة/إعادة تدوير"])},
  "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهة الإدارة"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط للحذف"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط للإضافة"])},
  "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محدد"])},
  "Pick some": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر البعض.."])},
  "Design capacity of the Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعة التصميمية للمكان"])},
  "Disposal Site Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خصائص موقع التخلص"])},
  "Nature Of Site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طبيعة الموقع"])},
  "Site Management Operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات إدارة الموقع"])},
  "Design And Studies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصميم والدراسات"])},
  "Fertilizer Factory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصنع أسمدة"])},
  "The ratio of the actual area used for landfill / Reception of waste in relation to the total area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة المساحة الفعلية الحالية المستخدمة للدفن/استقبال المخلفات بالنسبة للمساحة الكلية"])},
  "The height of the waste above ground level at the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارتفاع المخلفات عن سطح الأرض عند للموقع تقريباً"])},
  "The minimum height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقل ارتفاع"])},
  "The maximum height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعلى ارتفاع"])},
  "The depth of the waste from the surface of the ground is approximately at the burial site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمق المخلفات عن سطح الأرض عند موقع الدفن تقريباً"])},
  "Is it possible to increase the height of the waste on the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يمكن زيادة ارتفاع المخلفات بالموقع"])},
  "Does the area around the site allow to increase its area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل المنطقة المحيطة بالموقع تسمح بزيادة مساحته"])},
  "The nearest residential community to the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقرب تجمع سكني للموقع"])},
  "How far from the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم يبعد عن الموقع"])},
  "Adjacent land use the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعمالات الأراضي المجاورة الموقع"])},
  "Previous use of the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستخدام السابق للموقع؟"])},
  "Is the site fenced off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل الموقع محاط بسور"])},
  "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدينة"])},
  "Village": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قرية"])},
  "The depth of the groundwater of the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمق المياه الجوفية بالموقع"])},
  "Is the site located in the floods area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يقع الموقع في منطقة سيول أو فيضانات"])},
  "Are there groundwater wells within 1 km of the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد آبار مياه جوفية في حدود 1 كم من الموقع"])},
  "List the types of topsoil on the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذكر أنواع التربة السطحية الموجودة بالموقع"])},
  "Sandy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رملية"])},
  "Muddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طينية"])},
  "Asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسفلت"])},
  "Clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طفلة"])},
  "Rocky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صخرية"])},
  "What are the sources of waste received, and an approximate percentage of each": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي مصادر المخلفات الواردة، ونسبة تقريبية لكل منهم"])},
  "Is there a record of imports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد سجل للواردات"])},
  "Is there a hazardous waste, what is it?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مخلفات خطرة، ما هي؟"])},
  "Determine the quantities of waste entering the site (from records)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد كميات المخلفات التي تدخل الموقع (من السجلات)"])},
  "Is there a tariff for waste disposal on site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هناك تعريفة للتخلص من المخلفات بالموقع"])},
  "How is waste handled (facilities - equipment used - steps for movement and waste handling)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يتم التعامل مع المخلفات (المرافق-المعدات المستخدمة - خطوات تحرك والتعامل مع المخلفات)"])},
  "Is the waste covered on site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يتم تغطية المخلفات بالموقع"])},
  "Is there a weigh station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد محطة وزن"])},
  "Are there searchers on the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد نباشين بالموقع"])},
  "Are there traces of contamination of the leachate fluid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل توجد آثار لتلوث سوائل الرشح"])},
  "Are there visible emissions of gas (odor or something other than burning)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هناك انبعاثات واضحة للغاز (رائحة أو شيء غير الحرق)"])},
  "Do fires occur?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تحدث حرائق؟"])},
  "What kind?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما نوعها؟"])},
  "Approximately, how many days of burning are per year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي عدد أيام الحرق تقريباً في السنة"])},
  "The depth of the fire layer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمق طبقة الحريق"])},
  "What percentage of waste is burned (out of area)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما نسبة ما يتم حرقه من المخلفات (من المساحة)"])},
  "Domestic And Commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منزلي وتجاري"])},
  "Domestic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منزلي"])},
  "Industrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صناعي"])},
  "Demolish And Build": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هدم وبناء"])},
  "Medical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طبي"])},
  "Random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عشوائية"])},
  "Is the vault padded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل المدفن مبطن"])},
  "Is there a system for collecting leachate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد نظام لجمع سوائل الرشح"])},
  "Is there a system for collecting landfill gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل يوجد نظام لجمع غازات المدفن "])},
  "Are there design and engineering drawings for the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد رسومات تصميمية وهندسية للموقع"])},
  "Determine the type of studies that have been conducted for the site before the start of construction and operation works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد نوع الدراسات التي تم عملها للموقع قبل بدء أعمال الإنشاء والتشغيل"])},
  "Is there an operating and monitoring plan for the site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هـل يـوجد خطة تشغيل ورصد للموقع"])},
  "Rate of incoming waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل المخلفات الواردة"])},
  "Carrying capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة الاستيعابية"])},
  "Efficiency (lines running from total lines)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكفاءة (الخطوط التي تعمل من الخطوط الكلية)"])},
  "How is the manufacturing process done (equipment used - open or closed place - manufacturing steps)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تتم عملية التصنيع (المعدات المستخدمة - المكان مغلق أم مفتوح - خطوات التصنيع)"])},
  "Unit Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الوحدة"])},
  "Type of station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المحطة"])},
  "The number of emergency units used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الوحدات المستخدمة للطوارئ"])},
  "In the case of using steam units, is there heat recovery equipment?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حالة استخدام وحدات بخارية هل يوجد استرجاع للطاقة الحرارية (Heat recovery equipment)"])},
  "Unit Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوحدة"])},
  "Shape Used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشكل المستخدم"])},
  "Fuel Density": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كثافة الوقود"])},
  "Boiler Burner Types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكل الولاعة داخل الغلايات"])},
  "Are there any devices to control any pollutants for this unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد أي أجهزة للتحكم في أي ملوثات لهذه الوحدة"])},
  "Controlling Devices Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد أجهزة التحكم"])},
  "Conrolling Devices Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أين تركب أجهزة الإزالة"])},
  "Effeciency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكفاءة"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
  "Pollutant removal efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كفاءة إزالة الملوث"])},
  "Water Consumption Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية استهلاك المياه"])},
  "Total amount of wastewater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية مياه الصرف الكلية"])},
  "Amount of industrial wastewater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية مياه الصرف الصناعي"])},
  "Is there a water treatment plant for the power production plant?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد محطة معالجة مياه خاصة بمحطة إنتاج الطاقة"])},
  "Water purification to treat the water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنقية المياه لمعالجة المياه"])},
  "What is the treatment method (units and their efficiency to reduce and arrange each pollutant)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي طريقة المعالجة (الوحدات وكفاءتها لتقليل كل ملوث وترتيبها)"])},
  "Are there fuel tanks?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد تنكات الوقود"])},
  "Type of liquid organic materials tanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع تنكات المواد العضوية السائلة"])},
  "Amount of consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الاستهلاك"])},
  "Factories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصانع"])},
  "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطول"])},
  "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العرض"])},
  "Equipment used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعدات المستخدمة"])},
  "The place is closed or open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكان مغلق أم مفتوح "])},
  "Manufacturing steps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطوات التصنيع"])},
  "Gaseous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غازية"])},
  "Steam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بخارية"])},
  "Composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مركبة"])},
  "Some production lines have missing product classifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعض خطوط الإنتاج تفتقد إلى تصنيفات المنتجات"])},
  "Please enter type of visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء ادخال حالة الزيارة"])},
  "current Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون الحالي"])},
  "changed Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللون الذي تريده"])},
  "Upload File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الملف"])},
  "Change File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير الملف"])},
  "Well size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم البيارة"])},
  "The company responsible for scavenging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركة المسئولة عن الكسح"])},
  "The specified duration of scavenging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة المحددة للكسح"])},
  "What is the company spending the water on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علام تصرف الشركة المياه"])},
  "Chimney location on line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع المدخنة على الخط"])},
  "Control location on line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع جهاز التحكم على الخط"])},
  "LE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنيه"])},
  "Exclude From Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استثناء من استخراج البيانات"])},
  "Include In Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تضمين من استخراج البيانات"])},
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "Operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
  "New Entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدخل جديد"])},
  "Edit Entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل مدخل"])},
  "Issue Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التحرير"])},
  "Title and Description cannot be empty!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن ترك العنوان والوصف فارغين!"])},
  "Closure Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الغلق"])},
  "Pending Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب التأخر"])},
  "Has Parent Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل مشابها لمشكلة سابقة"])},
  "Plant type (main desalination method)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المحطة (طريقة التحلية الرئيسية)"])},
  "Tourist or residential station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محطة سياحية أم سكنية"])},
  "Areas served by the station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناطق التي تخدمها المحطة"])},
  "Source of desalinated water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصدر مياه التحلية"])},
  "Source of waste water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصدر مياه الصرف"])},
  "Socket type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المأخذ"])},
  "Water inlet to the station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدخل المياه للمحطة"])},
  "The distance between the water inlet and the station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسافة بين مدخل المياه و المحطة"])},
  "Plant Design Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة التصميمية للمحطة"])},
  "Actual Plant Power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة الفعلية للمحطة"])},
  "Added Plant Power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي الطاقة المتوقع إضافتها"])},
  "Plant efficiency for desalination process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كفاءة المحطة لعملية التحلية (Recovery rate)"])},
  "Station area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة المحطة"])},
  "Number of inhabitants served by the station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد السكان التي تخدمهم المحطة"])},
  "Electrical energy used in kilowatt hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطاقة الكهربية المستخدمة بالكيلو وات ساعة"])},
  "Amount of solid waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المخلفات الصلبة"])},
  "Method of disposal of solid waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة التخلص من المخلفات الصلبة"])},
  "Please don't leave until upload is done!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء عدم مغادرة الصفحة حتى اتمام التحميل!"])},
  "Resulting Salt Water": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المياه المالحة الناتجة (concentrate)"])},
  "Water Pollutants Concentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تركيز ملوثات المياه"])},
  "Before the desalination process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل عملية التحلية"])},
  "After the desalination process for the desalinated water (Permeate)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد عملية التحلية للمياه المحلاة (Permeate)"])},
  "After the desalination process (Brine/Concentrate)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد عملية التحلية (Brine/Concentrate)"])},
  "Power Consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استهلاك الطاقة"])},
  "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RO"])},
  "Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعالجة"])},
  "Before the treatment process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل عملية المعالجة"])},
  "After the treatment process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد عملية المعالجة"])},
  "Before the treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل المعالجة"])},
  "After the treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد المعالجة"])},
  "Pre-Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعالجة الأولية"])},
  "Post-Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعالجة النهائية"])},
  "Primary-Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعالجة الاساسية"])},
  "Secondary-Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعالجة الثنائية"])},
  "Tertiary-Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعالجة الثلاثية"])},
  "Amount of resulting salt water produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المياه المالحة الناتجة (concentrate)"])},
  "Distance between drainage place and the station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسافة بين مكان الصرف والمحطة"])},
  "Number of pumps used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المضخات المستخدمة"])},
  "Pumps Power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قدرة المضخات"])},
  "Is there a generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مولد كهرباء"])},
  "Is there a generator or another reason for fuel consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مولد كهرباء أو هدف أخر لاستهلاك الوقود"])},
  "Fuel used for the generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقود المستخدم للمولد"])},
  "Number of RO filters (membrane elements) units in the station": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد وحدات فلاتر RO (membrane elements) المتواجدة بالمحطة"])},
  "Filters change rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدل تغير الفلاتر"])},
  "Filter operating conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالات التشغيل للفلتر"])},
  "Net Driving Pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط عند التشغيل (Net Driving Pressure)"])},
  "Is there an energy recovery system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد نظام استرجاع للطاقة"])},
  "Percentage of Energy Recovered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الطاقة التي يتم استرجاعها"])},
  "RO system configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكوينات نظام RO"])},
  "Number of Mutlipass Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد وحدات ال Mutlipass"])},
  "Number of Multistage Units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد وحدات ال Multistage"])},
  "Is there a Coagulation Unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدة Coagulation"])},
  "The type of coagulant used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع ال coagulant المستخدم"])},
  "Is there a flocculation unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدة flocculation"])},
  "Is there a Scale inhibitor unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدة Scale inhibitor"])},
  "The type of inhibitor used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع ال inhibitor المستخدم"])},
  "Is there a Biocides unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدة Biocides"])},
  "The type of biocides used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع ال Biocides المستخدم"])},
  "Is there a dechlorination unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدة Dechlorination"])},
  "The type of dechlorination used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع ال Dechlorination المستخدم"])},
  "Is there a SEDIMENTATION unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدة SEDIMENTATION"])},
  "Is there another sand / media filter unit (GRANULAR MEDIA FILTRATION)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدة فلتر رمل/وسط آخر (GRANULAR MEDIA FILTRATION)"])},
  "The medium type if it is not sandy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الوسط إذا كان غير رملي"])},
  "It works by pressure or by gravity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعمل بالضغط أم بالجاذبية"])},
  "Is there a Cartridge filter unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد وحدة Cartridge filter"])},
  "Pressure needed to operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضغط اللازم للتشغيل"])},
  "Post-Treatment Phases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراحل المعالجة النهائية"])},
  "General Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات عامة"])},
  "Either this facility doesn't exist or you don't have permission to view it. If you think there is a problem please contact your administrator.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إما أن هذه المنشأة غير موجودة أو ليس لديك إذن لمشاهدتها. إذا كنت تعتقد أن هناك مشكلة الرجاء الاتصال بالمسئول."])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
  "Are there Extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد توسعات"])},
  "Lifting stations that the station receives from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسماء محطات الرفع التي تستقبل منها المحطة"])},
  "Places of water drainage after treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أماكن صرف المياه بعد معالجتها"])},
  "Beginning of drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية الصرف"])},
  "Ending of drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية الصرف"])},
  "Percentage of water leaving the plant without treatment (By-pass)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة المياه الي تخرج من المحطة بدون معالجة (By-pass)"])},
  "Station Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساحة المحطة"])},
  "Treatment Nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طبيعية المعالجة"])},
  "The ratio of industrial wastewater to sanitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الصرف الصناعي بالنسبة للصرف الصحي"])},
  "What are the pollutants for industrial sewage in the plant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما الملوثات الخاصة بالصرف الصناعي في المحطة"])},
  "Is exchange received from outside the network (such as scavengers)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يتم استقبال صرف من خارج الشبكة (مثل عربات كسح)"])},
  "Number of Inlet Chambers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد غرف الاستقبال"])},
  "Screens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشبكات والحواجز"])},
  "Types of Screens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي انواع الشبكات"])},
  "Grit Removal System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انظمة ازالة الرمل"])},
  "Types of Grit Removal System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي انواع انظمة ازالة الرمل"])},
  "Number of Connected Pumps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المضخات المتصلة"])},
  "Sedimentation Tank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حوض الترسيب"])},
  "Tank Shape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشكل الهندسي للحوض"])},
  "Tank Capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعة الحوض"])},
  "Tank Depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمق الحوض"])},
  "Sedimentation Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الحمأة المنتجة"])},
  "Treatment Way": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة المعالجة "])},
  "Trickling filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشح التقطير"])},
  "Type of media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هو نوع البيئة التي تنمو عليها البكتيريا"])},
  "Number of units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الوحدات"])},
  "Number of levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المراحل"])},
  "Number of filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المرشحات"])},
  "Sludge Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الحمأة من إجمالي انتاج المحطة"])},
  "Rotating Biological reactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفاعل الحيوي الدوار"])},
  "Cylinders dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبعاد الاسطوانة"])},
  "Activated sludge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحمأة المنشطة"])},
  "Type of refactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هو نوع المفاعل"])},
  "Number of airTub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد أحواض التهوية"])},
  "Number of tubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد أحواض الترسيب"])},
  "What is the percentage of sludge that is returned to the tank again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي نسبة الحمأة التي يتم اعادتها للتنك مرة أخرى"])},
  "Number of motors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المواتير المستخدمة للتهوية"])},
  "Filter configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة تواجد الفلتر بالنسبة لتنك التفاعل"])},
  "MBR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر المفاعل الحيوي (MBR)"])},
  "Is there Sterilization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد تعقيم"])},
  "Is there Grit Removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مرشحات رملية"])},
  "Is there Removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يوجد مرشحات أغشية"])},
  "Sludge Solid Wastes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخلفات الصلبة (الحمأة)"])},
  "Thickennig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التركيز"])},
  "Stabilization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التثبيت"])},
  "Dewatering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فصل المياه من الحمأة"])},
  "Is it Treated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يتم معالجة الحمأة قبل التخلص منها"])},
  "Sludge Removal Ways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق التخلص من الحمأة"])},
  "Other Wastes Amount Produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المخلفات الأخرى المنتجة"])},
  "Other Waste Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصادر المخلفات الأخرى"])},
  "Other Waste Removal Ways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق التخلص منها"])},
  "Fuel Consumption Goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هو هدف استهلاك الوقود"])},
  "Before Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل عملية المعالجة"])},
  "After Treatment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد عملية المعالجة"])},
  "Average monthly or annual, or direct measurement sample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط شهري او سنوي، ام عينة قياس مباشر"])},
  "Semi Sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاعات مجمعة"])},
  "Assign yourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضفني كمراجع"])},
  "Operation Succeeded!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمت العملية بنجاح!"])},
  "Operation Failed, Please try again later!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذرت العملية برجاء المحاولة مرة أخري!"])},
  "Verifier Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المراجع"])},
  "Confirm Submitting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد التسليم"])},
  "Remove yourself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أزل نفسك كمراجع"])},
  "Export Selected Facilities (Air)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخرج المنشآت المختارة (هواء)"])},
  "Export Selected Facilities (Water)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخرج المنشآت المختارة (مياه)"])},
  "Number of incinerators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما عدد المحارق"])},
  "Burnt waste amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية المخلفات المحروقة"])},
  "Burnt waste type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع المخلفات التي يتم حرقها"])},
  "Unrecycled waste destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما مصير المخلفات التي لم يتم تدويرها"])},
  "Calculation Emission Rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب معدلات الانبعاث"])},
  "Water Calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابات المياه"])},
  "Licensed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرخص"])},
  "Not Licensed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مرخص"])},
  "Aggregation Level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستوى التجميع"])},
  "Standalone Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشآت"])},
  "Departments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقسام/مراكز"])},
  "Whole Egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل مصر"])},
  "Save current configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ الإعدادات الحالية"])},
  "Please enter a name to save your configurations with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك ادخل اسم لحفظ الإعدادات به"])},
  "Save configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ الإعدادات"])},
  "Saved successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحفظ بنجاح"])},
  "Failed to save data. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعذر حفظ البيانات. من فضلك أعد المحاولة لاحقا."])},
  "Clear all options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح جميع الاختيارات"])},
  "Export Map as image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تصدير الخريطة كصورة "])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في"])},
  "Administration Boundary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الإداري"])},
  "Final outlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصب النهائي"])},
  "Change Data Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم خط البيان"])},
  "Change Axes Font Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الخط على المحاور"])},
  "Please use facility search to get these statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك استخدم البحث التفصيلي للحصول على هذه الإحصائيات"])},
  "Please select a pollutant to get these statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر ملوث للحصول على هذه الإحصائيات"])},
  "Show percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أظهار النسبة المئوية  "])},
  "By Kilometer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيلو متر مربع"])},
  "GSector border": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدود الأقاليم"])},
  "Deleted successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحذف بنجاح."])},
  "Please select option to be deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك اختر إعداد ليتم حذفه."])},
  "Suppress Problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجاهل المشكلة"])},
  "Submit Forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسليم الاستمارات"])},
  "Select User Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار بيانات المستخدم"])},
  "Select Suggested Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار البيانات المقترحة"])},
  "Reverse Coordinate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عكس الموقع الجغرافي"])},
  "Department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المركز"])},
  "User Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المستخدم"])},
  "Suggested Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات المقترحة"])},
  "Served": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخدوم"])},
  "Deprived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محروم"])},
  "Drainage Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الصرف"])},
  "Drainage Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المصرف"])},
  "Drainage Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكان الصرف"])},
  "Sewage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صرف صحي"])},
  "Mixed Drainage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صرف مختلط"])},
  "not entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مدخل"])},
  "Drains Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المصارف"])},
  "Drains by sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف بالقطاع"])},
  "Drainage geographically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف جغرافيا"])},
  "Upload Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعلام عن الرفع"])},
  "Working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعمل"])},
  "Not Working": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تعمل"])},
  "Power plant capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قدرة المحطة الإنتاجية"])},
  "agriculture wastes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخلفات زراعية"])},
  "industrial wastes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخلفات صناعية"])},
  "traditional wastes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخلفات البلدية"])},
  "Scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقياس الرسم"])},
  "Unify Colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توحيد الألوان"])},
  "Labels Font Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الخط"])},
  "Show Labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إظهار البيانات"])},
  "logarithmic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقياس لوغاريتمي"])},
  "1:1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["١٠٠٠:١"])},
  "1:1,000,000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["١،٠٠٠،٠٠٠:١"])},
  "Hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخفاء"])},
  "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إظهار"])},
  "Normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عادي"])},
  "Colored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملونة"])},
  "Unified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موحدة اللون"])},
  "Update Map Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث الموقع علي الخريطة"])},
  "FoundFacilitiesMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["لم نجدأي نتائج"]), _normalize(["وجدنا نتيجة واحدة"]), _normalize(["وجدنا ", _interpolate(_named("count")), " نتيجة"])])},
  "The Sewage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصرف"])},
  "Place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكان"])},
  "Pollution Per Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التلوث حسب القطاع"])},
  "Pollution Per Crop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التلوث حسب المحصول"])},
  "Top Five Solid Waste Pollutants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعلى خمسة ملوثات للمخلفات الصلبة"])},
  "Land Area Per Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساحة الأرضية حسب القطاع"])},
  "Pollution Per Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التلوث حسب المحافظة"])},
  "Land Area Per Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساحة الأرضية حسب المحافظة"])},
  "Number of Animals Per Governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحيوانات حسب المحافظة"])},
  "Animals Number Per Sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحيوانات حسب القطاع"])},
  "Search Exact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث دقيق"])},
  "Search Approximate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث تقريبي"])},
  "Statement of factories that discharge directly into waterways.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيان بالمصانع التي تصب مباشرة علي المجاري المائية."])},
  "Crop group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة المحاصيل"])},
  "Crop Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المحصول"])},
  "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرض"])},
  "Crop Subgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة الفرعية للمحصول"])},
  "Sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجمالي"])},
  "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طباعة"])},
  "Export to Excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير إلى إكسل"])},
  "Sewage Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية الصرف"])},
  "Retrieve BackUp of facility data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استرجاع نسخة احتياطية من بيانات المنشأة"])},
  "Recalculate Submitted Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة حساب المنشآت المقدمة"])},
  "Auto Submit non Submitted Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم المنشآت غير المقدمة تلقائيًا"])},
  "Recalculate Locations for All Facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة حساب المواقع لجميع المنشآت"])},
  "Trend of solid waste generation rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معدلات توليد المخلفات الصلبة"])},
  "Trend of air pollution from solid waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلوث الهواء من المخلفات الصلبة"])},
  "Solid Waste Types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع المخلفات الصلبة"])},
  "Search Crops Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث عن معلومات المحاصيل"])},
  "Lookup Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات البحث"])},
  "Please wait for the data to load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برجاء الانتظار حتى تحميل البيانات"])},
  "SubSectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قطاعات فرعية"])},
  "Total Load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي الحمل"])},
  "Latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحدث"])}
}