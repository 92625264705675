import { createRouter, createWebHistory } from "vue-router";
import { store } from "./store";


const AgricultureFactoriesPerSector = () => import("./components/reports/AgricultureFactoriesPerSector.vue")
const MapView = () => import("./components/map/MapView.vue")



const Login = () => import("./login.vue");
const Settings = () => import("./components/settings/Settings.vue");
const Messages = () => import("./components/Messages.vue");
const GenericRequests = () => import("./components/GenericRequests.vue");
const Tickets = () => import("./components/tickets/Tickets.vue");
const NotFoundComponent = () => import("./components/NotFound.vue");
const UnderConstruction = () => import("./components/UnderConstruction.vue");


const AdminCardsHome = () => import("./components/admin/AdminCardsHome");
const MiniAdminCardsHome = () => import("./components/admin/MiniAdminCardsHome");



//Facility Administration
const AdminFacilities = () => import("./components/admin/AdminFacilities");
const AdminFacilitySearch = () => import("./components/admin/AdminFacilitySearchForm");
const AdminFacilityDelete = () => import("./components/admin/AdminFacilityDeleteForm");

//Sectors Administration
const LawsandRegulations = () => import("./components/LawsAndRegulations.vue");
const ImportPage = () => import("./components/Import.vue");
const ImportInquiry = () => import("./components/ImportInquiry.vue");
const MapsTransition = () => import("./components/Maps Transition/MapsTransition.vue");
const Map = () => import("./components/map/MapView.vue");
const SMap = () => import("./components/specializeddecisionmaker/SimplifiedMapView.vue");
const ReportsTransition = () => import("./components/Reports Transition/ReportsTransition.vue");
const ReportsTransitionSpecialized = () => import("./components/Reports Transition/ReportsTransitionSpecialized.vue");
const PollutionPerSector = () => import("./components/reports/PollutionPerSector.vue");
const PollutionPerFacility = () => import("./components/reports/PollutionPerFacility");
const EmissionsPerSector = () => import("./components/reports/EmissionsPerSector");
const FactoriesPerAllCities = () => import("./components/reports/FactoriesPerAllCities");
const FactoriesPerSector = () => import("./components/reports/FactoriesPerSector.vue");
const FactoriesPerSectorNew = () => import("./components/reports/FactoriesPerSectorNew.vue");
const TopFivePollutantPerGovernorate = () => import("./components/reports/TopFivePollutantPerGovernorate");
const TopFivePollutantPerSector = () => import("./components/reports/TopFivePollutantPerSector.vue");
const PollutantHistory = () => import("./components/reports/PollutantHistory");
const TopFacilitiesProducingPollutants = () => import("./components/reports/TopFacilitiesProducingPollutants");
const PollutantTrend = () => import("./components/reports/PollutantTrend");
const SearchByProduct = () => import("./components/reports/SearchByProduct");
const formFillers = () => import("./components/form filler supervisor/formFillers");
const formFillerDetails = () => import("./components/form filler supervisor/formFillerDetails");
const FacilityForm = () => import("./components/facility/FacilityMainForm.vue");
const Facility = () => import("./components/facility/Facility.vue");

const FormFillerSupervisor = () => import("./components/form filler supervisor/main");

const DataVerifierMain = () => import("./components/data verifier/DataVerifier");
const DataVerifierHome = () => import("./components/data verifier/DataVerifierHomePage");

const FacilityUser = () => import("./components/user facility/UserFacility");
const FacilityUserHomePage = () => import("./components/user facility/UserFacilityHomePage");

const Representative = () => import("./components/representative/Representative");
const RepresentativeHomePage = () => import("./components/representative/RepresentativeHomePage");
const LandingPage = () => import("./landingpage/LandingPage.vue");

const Problems = () => import("./components/problems/Problems.vue");

const DumpHome = () => import("./components/dump user/home/DumpHome.vue");

const WaterUser = () => import("./components/water user/home/WaterUser.vue");
const AirUser = () => import("./components/air user/home/AirUser.vue");
const SpecializedDecisionMaker = () => import("./components/specializeddecisionmaker/home/SpecializedDecisionMaker.vue");
const AirPollution = () => import("./components/specializeddecisionmaker/AirPollution/AirPollution.vue");
const AirPollutionAmongSectors = () => import("./components/specializeddecisionmaker/AirPollution/AirPollutionAmongSectors.vue");
const SarfPerSubSector = () => import("./components/specializeddecisionmaker/WaterPollution/SarfPerSubSector.vue");
const WasteWaterClassification = () => import("./components/specializeddecisionmaker/WaterPollution/WasteWaterClassification.vue");
const WaterPollution = () => import("./components/specializeddecisionmaker/WaterPollution/WaterPollution.vue");
const IndustrialSolidWaste = () => import("./components/specializeddecisionmaker/IndustrialSolidWaste/IndustrialSolidWaste.vue");
const IndustrialSolidWastePerSector = () => import("./components/specializeddecisionmaker/IndustrialSolidWaste/IndustrialSolidWastePerSector.vue");
const IndustrialSolidWasteDangerous = () => import("./components/specializeddecisionmaker/IndustrialSolidWaste/IndustrialSolidWasteDangerous");
const WaterUsage = () => import("./components/water user/waterUsage/WaterUsage.vue");
const Sewage = () => import("./components/water user/sewage/Sewage.vue");
const SewageTreatment = () => import("./components/water user/sewageTreatment/SewageTreatment.vue");
const TopIndustrialSectorsWaterUsage = () => import("./components/water user/waterUsage/TopIndustrialSectorsWaterUsage.vue");
const HotCoolCycleFactoriesTable = () => import("./components/water user/waterUsage/HotCoolCycleFactoriesTable.vue");
const DangerousFactories = () => import("./components/water user/sewageTreatment/DangerousFactories.vue");
const LiquidContamination = () => import("./components/water user/sewageTreatment/LiquidContamination.vue");
const SewageTreatmentPlantsReport = () => import("./components/water user/sewageTreatment/SewageTreatmentPlantsReport.vue");
const TopSewageDumpingFactories = () => import("./components/water user/sewageTreatment/TopSewageDumpingFactories.vue");
const TopSlaughterHouses = () => import("./components/water user/sewageTreatment/TopSlaughterHouses.vue");
const WaterTreatmentPlants = () => import("./components/water user/sewageTreatment/WaterTreatmentPlants.vue");
const SewageDetailedMap = () => import("./components/water user/sewageTreatment/SewageDetailedMap.vue");
const AgriculturalSewage = () => import("./components/water user/sewage/AgriculturalSewage.vue");
const FactoriesDrainingToSewage = () => import("./components/water user/sewage/FactoriesDrainingToSewage.vue");
const SewageTreatmentFactories = () => import("./components/water user/sewage/SewageTreatmentFactories.vue");
const LiquidWasteWeight = () => import("./components/water user/sewageTreatment/LiquidWasteWeight.vue");
const AirWasteWeight = () => import("./components/water user/sewageTreatment/AirWasteWeight.vue");
const AllPollutantsWeight = () => import("./components/water user/sewage/AllPollutantsWeight.vue");
const SewageHeatMap = () => import("./components/water user/sewage/SewageHeatMap.vue");
const WaterSewageFactories = () => import("./components/water user/sewage/WaterSewageFactories.vue");
const SewageSlaughterHouses = () => import("./components/water user/sewage/SewageSlaughterHouses.vue");
const DumpSites = () => import("./components/water user/sewage/DumpSites.vue");
const SewageSources = () => import("./components/water user/sewage/SewageSources.vue");
const GenericSearchMap = () => import("./components/dump user/GenericSearchMap.vue");
const MainMap = () => import("./components/map/MainMap.vue");

import { userTypes } from "./routes/userTypes.js";
import admin_routes from "./routes/admin_routes.js";
import agriculture_routes from "./routes/agriculture_routes.js";
import solid_routes from "./routes/solid_routes.js";

const routes = admin_routes.concat(agriculture_routes, solid_routes, [
  {
    path: "/welcomepage",
    name: "LandingPage",
    component: LandingPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    name: "DumpHome",
    path: "/WasteUser",
    component: DumpHome,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WasteUser] },
  },
  {
    name: "AgricultureWaste",
    path: "/map/:name/:superId",
    component: GenericSearchMap,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WasteUser] },
    props: { name: "agriculture wastes", superId: 1 },
  },
  {
    name: "IndustrialWaste",
    path: "/map/:name/:superId",
    component: GenericSearchMap,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WasteUser] },
    props: { name: "industrial wastes", superId: 7 },
  },
  {
    name: "TraditionalWaste",
    path: "/map/:name/:superId",
    component: GenericSearchMap,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WasteUser] },
    props: { name: "traditional wastes", superId: 4 },
  },

  {
    name: "WaterUser",
    path: "/WaterUser",
    component: WaterUser,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WaterUser] },
  },
  {
    name: "AirUser",
    path: "/AirUser",
    component: AirUser,
    meta: { requiresAuth: true, allowedUsers: [userTypes.AirUser] },
  },
  {
    name: "SpecializedDecisionMaker",
    path: "/SpecializedDecisionMaker",
    component: SpecializedDecisionMaker,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
  },
  {
    name: "AirPollution",
    path: "/AirPollution",
    component: AirPollution,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
  },
  {
    name: "AirPollutionAmongSectors",
    path: "/AirPollutionAmongSectors",
    component: AirPollutionAmongSectors,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
    props: {
      name: "Distribution of total pollution loads among sectors",
      superId: null,
    },
  },
  {
    name: "SarfPerSubSector",
    path: "/SarfPerSubSector",
    component: SarfPerSubSector,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
    props: {
      name: "Quantity of industrial facilities disbursement according to sub-sector",
      superId: null,
    },
  },
  {
    name: "IndustrialSolidWaste",
    path: "/IndustrialSolidWaste",
    component: IndustrialSolidWaste,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
    props: { name: "IndustrialSolidWaste", superId: null },
  },
  {
    name: "IndustrialSolidWastePerSector",
    path: "/IndustrialSolidWastePerSector",
    component: IndustrialSolidWastePerSector,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
    props: { name: "Quantity of industrial solid waste", superId: null },
  },
  {
    name: "IndustrialSolidWasteDangerous",
    path: "/IndustrialSolidWasteDangerous",
    component: IndustrialSolidWasteDangerous,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
    props: {
      name: "Hazardous industrial waste and methods of disposal",
      superId: null,
    },
  },
  {
    name: "WasteWaterClassification",
    path: "/WasteWaterClassification",
    component: WasteWaterClassification,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
    props: {
      name: "Waste Water Classification for Industrial Facilities",
      superId: null,
    },
  },

  {
    name: "WaterPollution",
    path: "/WaterPollution",
    component: WaterPollution,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
  },
  {
    name: "WaterUsage",
    path: "/waterusage",
    component: WaterUsage,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "TopIndustrialSectorsWaterUsage",
    path: "/topindustrial",
    component: TopIndustrialSectorsWaterUsage,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "HotCoolCycleFactoriesTable",
    path: "/hotcoolfactories",
    component: HotCoolCycleFactoriesTable,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WaterUser] },
  },
  {
    name: "Sewage",
    path: "/sewage",
    component: Sewage,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "AgriculturalSewage",
    path: "/AgriculturalSewage",
    component: AgriculturalSewage,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WaterUser] },
  },
  {
    name: "FactoriesDrainingToSewage",
    path: "/FactoriesDrainingToSewage",
    component: FactoriesDrainingToSewage,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "SewageTreatmentFactories",
    path: "/SewageTreatmentFactories",
    component: SewageTreatmentFactories,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "SewageTreatment",
    path: "/sewagetreatment",
    component: SewageTreatment,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "DangerousFactories",
    path: "/DangerousFactories",
    component: DangerousFactories,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "LiquidContamination",
    path: "/LiquidContamination",
    component: LiquidContamination,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "SewageTreatmentPlantsReport",
    path: "/SewageTreatmentPlantsReport",
    component: SewageTreatmentPlantsReport,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "TopSewageDumpingFactories",
    path: "/TopSewageDumpingFactories",
    component: TopSewageDumpingFactories,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "TopSlaughterHouses",
    path: "/TopSlaughterHouses",
    component: TopSlaughterHouses,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "WaterTreatmentPlants",
    path: "/WaterTreatmentPlants",
    component: WaterTreatmentPlants,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "LiquidWasteWeight",
    path: "/LiquidWasteWeight",
    component: LiquidWasteWeight,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "AirWasteWeight",
    path: "/AirWasteWeight",
    component: AirWasteWeight,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WaterUser] },
  },
  {
    name: "AllPollutantsWeight",
    path: "/AllPollutantsWeight",
    component: AllPollutantsWeight,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.AirUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "SewageHeatMap",
    path: "/SewageHeatMap",
    component: SewageHeatMap,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "SewageDetailedMap",
    path: "/SewageDetailedMap",
    component: SewageDetailedMap,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WaterUser] },
  },
  {
    name: "WaterSewageFactories",
    path: "/WaterSewageFactories",
    component: WaterSewageFactories,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "SewageSlaughterHouses",
    path: "/SewageSlaughterHouses",
    component: SewageSlaughterHouses,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WaterUser] },
  },
  {
    name: "DumpSites",
    path: "/DumpSites",
    component: DumpSites,
    meta: { requiresAuth: true, allowedUsers: [userTypes.WaterUser] },
  },
  {
    name: "SewageSources",
    path: "/SewageSources",
    component: SewageSources,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.WaterUser,
        userTypes.Super,
        userTypes.DecisionMaker,
      ],
    },
  },
  {
    name: "AdminCardsHome",
    path: "/adminhome",
    component: AdminCardsHome,
    meta: { requiresAuth: true, allowedUsers: [userTypes.Admin] },
  },
  {
    name: "MiniAdminCardsHome",
    path: "/m_adminhome",
    component: MiniAdminCardsHome,
    meta: { requiresAuth: true, allowedUsers: [userTypes.MiniAdmin] },
  },
  {
    name: "FormFillerSupervisor",
    path: "/formfillersupervisor",
    component: FormFillerSupervisor,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.FormFillerSuperVisor],
    },
    children: [
      {
        name: "FormFillerSupervisorHome",
        path: "home",
        meta: {
          requiresAuth: true,
          allowedUsers: [userTypes.FormFillerSuperVisor],
        },
        component: Map,
      },
    ],
  },
  {
    name: "DataVerifier",
    path: "/dataverifier",
    component: DataVerifierMain,
    meta: { requiresAuth: true, allowedUsers: [userTypes.DataVerifier] },
    children: [
      {
        name: "DataVerifierHome",
        path: "home",
        meta: { requiresAuth: true, allowedUsers: [userTypes.DataVerifier] },
        component: DataVerifierHome,
      },
      {
        name: "VerifierSearchFacility",
        path: "/verifier/facility/search",
        meta: { requiresAuth: true, allowedUsers: [userTypes.DataVerifier] },
        component: AdminFacilitySearch,
      },
      {
        name: "VerifierDeleteFacility",
        path: "/verifier/facility/delete",
        meta: { requiresAuth: true, allowedUsers: [userTypes.DataVerifier] },
        component: AdminFacilityDelete,
      },
    ],
  },
  {
    name: "FacilityUser",
    path: "/facilityuser",
    component: FacilityUser,
    meta: { requiresAuth: true, allowedUsers: [userTypes.FacilityOwner] },
    children: [
      {
        name: "FacilityUserHome",
        path: "home",
        meta: { requiresAuth: true, allowedUsers: [userTypes.FacilityOwner] },
        component: FacilityUserHomePage,
      },
    ],
  },
  {
    name: "representative",
    path: "/representative",
    component: Representative,
    meta: { requiresAuth: true, allowedUsers: [userTypes.DataCollector] },
    children: [
      {
        name: "RepresentativeHome",
        path: "home",
        meta: { requiresAuth: true, allowedUsers: [userTypes.DataCollector] },
        component: RepresentativeHomePage,
      },
    ],
  },
  {
    name: "mainMap",
    path: "/map",
    component: MainMap,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
        userTypes.WaterUser,
      ],
    },
    children: [
      {
        name: "MapsTransition",
        path: "mapsTransition",
        meta: {
          requiresAuth: true,
          allowedUsers: [
            userTypes.Super,
            userTypes.DecisionMaker,
            userTypes.DataVerifier,
            userTypes.DataCollector,
            userTypes.SpecializedDecisionMaker,
          ],
        },
        component: MapsTransition,
      },
      {
        name: "MapAllOptions",
        path: ":type/:superSectorName/:pollutantprops",
        meta: {
          requiresAuth: true,
          allowedUsers: [
            userTypes.Super,
            userTypes.DecisionMaker,
            userTypes.DataCollector,
            userTypes.DataVerifier,
            userTypes.WaterUser,
            userTypes.AirUser,
            userTypes.SpecializedDecisionMaker,
          ],
        },
        component: Map,
        props: true,
      },
      {
        name: "MapNoPollutant",
        path: ":type/:superSectorName/",
        meta: {
          requiresAuth: true,
          allowedUsers: [
            userTypes.Super,
            userTypes.DecisionMaker,
            userTypes.DataCollector,
            userTypes.DataVerifier,
            userTypes.WaterUser,
            userTypes.AirUser,
            userTypes.SpecializedDecisionMaker,
          ],
        },
        component: Map,
        props: true,
      },
      {
        name: "MapNoSuperSector",
        path: ":type/",
        meta: {
          requiresAuth: true,
          allowedUsers: [
            userTypes.Super,
            userTypes.DecisionMaker,
            userTypes.DataCollector,
            userTypes.DataVerifier,
            userTypes.WaterUser,
            userTypes.AirUser,
            userTypes.SpecializedDecisionMaker,
          ],
        },
        component: Map,
        props: true,
      },
      {
        name: "Map",
        path: "main",
        meta: {
          requiresAuth: true,
          allowedUsers: [
            userTypes.Super,
            userTypes.DecisionMaker,
            userTypes.DataCollector,
            userTypes.DataVerifier,
            userTypes.AirUser,
            userTypes.WaterUser,
            userTypes.SpecializedDecisionMaker,
          ],
        },
        component: Map,
        //props:true,
      },
      {
        name: "SMap",
        path: "smap",
        meta: {
          requiresAuth: true,
          allowedUsers: [
            userTypes.Super,
            userTypes.DecisionMaker,
            userTypes.DataCollector,
            userTypes.DataVerifier,
            userTypes.AirUser,
            userTypes.WaterUser,
            userTypes.SpecializedDecisionMaker,
          ],
        },
        component: SMap,
      },
    ],
  },

  {
    name: "ReportsTransition",
    path: "/reportsTransition",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
        userTypes.WaterUser,
        userTypes.AirUser,
      ],
    },
    component: ReportsTransition,
  },
  {
    name: "ReportsTransitionSpecialized",
    path: "/ReportsTransitionSpecialized",
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.SpecializedDecisionMaker],
    },
    component: ReportsTransitionSpecialized,
  },

  {
    name: "pollution-per-sector",
    path: "/pollution-per-sector/:name/:superId",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
        userTypes.WaterUser,
        userTypes.AirUser,
        userTypes.AgricultureUser,
      ],
    },
    component: PollutionPerSector,
    props: true,
  },
  {
    name: "pollution-per-facility",
    path: "/pollution-per-facility/:name/:superId",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.WaterUser,
        userTypes.AirUser,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: PollutionPerFacility,
    props: true,
  },
  {
    name: "emissions-per-sector",
    path: "/emissions-per-sector/:name",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.WaterUser,
        userTypes.AirUser,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: EmissionsPerSector,
    props: true,
  },
  {
    name: "factories-per-All-city",
    path: "/factories-per-All-city",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: FactoriesPerAllCities,
  },
  {
    name: "factories-per-sector",
    path: "/factories-per-sector/:name/:superId",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
        userTypes.AgricultureUser
      ],
    },
    component: FactoriesPerSector,
    props: true,
  },
  {
    name: "factories-per-sector-new",
    path: "/factories-per-sector-new/:name/:superId",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: FactoriesPerSectorNew,
    props: true,
  },
  {
    name: "top-five-pollution-per-governorate",
    path: "/top-five-pollution-per-governorate",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: TopFivePollutantPerGovernorate,
  },
  {
    name: "top-five-pollution-per-sector",
    path: "/top-five-pollution-per-sector/:name/:superId",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.AirUser,
        userTypes.WaterUser,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: TopFivePollutantPerSector,
    props: true,
  },
  {
    name: "pollutant-history",
    path: "/pollutant-history",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.WaterUser,
        userTypes.AirUser,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: PollutantHistory,
  },
  {
    name: "TopFacilitiesProducingPollutants",
    path: "/TopFacilitiesProducingPollutants",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.WaterUser,
        userTypes.AirUser,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: TopFacilitiesProducingPollutants,
  },
  {
    name: "pollutant-trend",
    path: "/pollutant-trend",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.WaterUser,
        userTypes.AirUser,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: PollutantTrend,
  },
  {
    name: "top-five-pollution-per-governorate",
    path: "/top-five-pollution-per-governorate",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: TopFivePollutantPerGovernorate,
  },
  {
    name: "top-five-pollution-per-sector",
    path: "/top-five-pollution-per-sector/:name/:superId",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.AirUser,
        userTypes.WaterUser,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: TopFivePollutantPerSector,
    props: true,
  },
  {
    name: "search-by-product",
    path: "/search-by-product/:name/:superId",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DecisionMaker,
        userTypes.DataVerifier,
      ],
    },
    component: SearchByProduct,
    props: true,
  },
  {
    name: "Problems",
    path: "/problems",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.DataVerifier,
        userTypes.FormFillerSuperVisor,
      ],
    },
    component: Problems,
  },
  {
    name: "FormFillers",
    path: "/formfillers",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.Admin,
        userTypes.MiniAdmin,
        userTypes.ContentCreator,
        userTypes.DataVerifier,
        userTypes.FormFillerSuperVisor,
      ],
    },
    component: formFillers,
  },
  {
    name: "FormFillerDetails",
    path: "/formfiller/:id/name/:name",
    component: formFillerDetails,
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.Admin,
        userTypes.MiniAdmin,
        userTypes.ContentCreator,
        userTypes.DataVerifier,
        userTypes.FormFillerSuperVisor,
      ],
    },
    props: true,
  },
  {
    name: "GenericRequests",
    path: "/genericrequests",
    component: GenericRequests,
    meta: {
      requiresAuth: true,
      allowedUsers: [userTypes.Super, userTypes.Admin,userTypes.MiniAdmin ],
    },
  },
  {
    name: "FacilityFormPage",
    path: "/facility/:id/form/:year",
    component: FacilityForm,
    meta: { requiresAuth: true, allowedUsers: Object.values(userTypes) },
    props: true,
  },
  {
    name: "FacilityFormMainPage",
    path: "/facilityform/:formid",
    component: FacilityForm,
    meta: { requiresAuth: true, allowedUsers: Object.values(userTypes) },
    props: true,
  },
  {
    name: "CreateFacility",
    path: "/facility/create",
    meta: { requiresAuth: true, allowedUsers: [userTypes.DataCollector] },
    component: AdminFacilities,
    props: { show: "createFacility" },
  },
  {
    name: "Regulations",
    path: "/regulations",
    component: LawsandRegulations,
    meta: { requiresAuth: true, allowedUsers: Object.values(userTypes) },
    props: { type: "show" },
  },
  {
    name: "Tickets",
    path: "/tickets",
    component: Tickets,
    meta: { requiresAuth: true, allowedUsers: Object.values(userTypes) },
  },
  {
    name: "Settings",
    path: "/settings",
    component: Settings,
    meta: { requiresAuth: true, allowedUsers: Object.values(userTypes) },
  },
  {
    name: "Messages",
    path: "/messages",
    component: Messages,
    meta: { requiresAuth: true, allowedUsers: Object.values(userTypes) },
  },
  {
    name: "PrintReport",
    path: "/printreport",
    component: {
      template:
        '<div class="notification"><p class="has-text-centered">{{"Under Construction" | translate}}</p></div>',
    },
    meta: { requiresAuth: true, allowedUsers: Object.values(userTypes) },
  },
  {
    path: "/",
    redirect: { name: store.getters.HomePageRouteName },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundComponent,
  },
]);

export const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(process.env.VUE_APP_DEPLOY_PATH),
  routes, // short for `routes: routes`
});

//window.router = router;
//const st = store
router.beforeEach((to, from, next) => {
  // console.log(to);
  // if (to.meta.requiresAuth)
  //     console.log(store.getters.IsUserTypeAllowedToRoute(to.meta.allowedUsers));
  if (to.meta.requiresAuth && !store.state.isLoggedIn) {
    //   console.log("1");
    next({ name: "Login" });
  } else if (
    to.meta.requiresAuth &&
    !store.getters.IsUserTypeAllowedToRoute(to.meta.allowedUsers)
  ) {
    //  console.log("12");
    next({ name: store.getters.HomePageRouteName });
  } else {
    //  console.log("123");
    next();
  }
});
