export default {
  "Solid waste disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخلص"])},
  "Biological treatment of solid waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعالجة البيلوجية"])},
  "Incineration and open burning of waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحرق"])},
  "wastewater Treatment and discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معالجة الصرف"])},
  "Other waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملوثات أخري"])},
  "Collected in Landfills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدافن"])},
  "Collected in Dump Sites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكبات"])},
  "Collected Recycled Fact.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة تدوير (مجمع)"])},
  "Uncollected Burnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرق (غير مجمع)"])},
  "Uncollected Recycled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة تدوير (غير مجمع)"])},
  "SolidWaste Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة المخلفات"])},
  "SolidWaste Efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كفاءة إدارة المخلفات"])},
  "Disposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخلص"])},
  "Re-Used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة الاستخدام"])},
  "Re-Cycled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة التدوير"])},
  "Incinerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحرق"])},
  "Total Clinical Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخلفات طبية"])},
  "Collected MSW waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخلفات البلدية"])},
  "Industrial Waste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخلفات صناعية"])}
}