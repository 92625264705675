const ImportPage = () => import("../components/Import.vue");
const SolidWasteMain = () =>
  import("../components/solid waste user/SolidWasteMain.vue");
const SolidWasteUser = () =>
  import("../components/solid waste user/SolidWasteUser.vue");
const SolidWaste = () =>
  import("../components/solid waste user/SolidWaste.vue");
const AgricultureRequests = () =>
  import("../components/admin/agriculture/AgricultureRequests.vue");
const ChatPage = () => import("../components/admin/ChatPage.vue")

const MapView = () => import("../components/map/MapView.vue");
const TopFivePollutantPerSector = () =>
  import("../components/reports/TopFivePollutantPerSector.vue");
const PollutionPerSector = () =>
  import("../components/reports/PollutionPerSector.vue");
const PollutionPerFacility = () =>
  import("../components/reports/PollutionPerFacility");
const FactoriesPerSector = () =>
  import("../components/reports/FactoriesPerSector.vue");

import SolidWasteEfficiency from "../components/agriculture user/SolidWasteEfficiency.vue";
import SWGenerationManagement from "../components/reports/SWGenerationManagement.vue";
import { userTypes } from "./userTypes";

export default [
  {
    name: "SolidWasteUser",
    path: "/SolidWasteUser",
    meta: {
      requiresAuth: true,
      allowedUsers: [
        userTypes.Super,
        userTypes.Admin,
        userTypes.ContentCreator,
        userTypes.DecisionMaker,
        userTypes.WasteUser,
      ],
    },
    component: SolidWasteMain,
    children: [
      {
        name: "SolidWasteUserDashboard",
        path: "dashboard",
        meta: {
          requiresAuth: true,
          allowedUsers: [
            userTypes.Super,
            userTypes.DecisionMaker,
            userTypes.DataVerifier,
            userTypes.WasteUser,
          ],
        },
        component: SolidWasteUser,
      },
      {
        name: "SolidWaste",
        path: "",
        meta: {
          requiresAuth: true,
          allowedUsers: [
            userTypes.Super,
            userTypes.Admin,
            userTypes.ContentCreator,
            userTypes.DecisionMaker,
            userTypes.WasteUser,
          ],
        },
        component: SolidWaste,
        children: [
          {
            name: "SolidWaste_top-five-pollution-per-sector",
            path: "reports/top-five-pollution-per-sector",
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.DecisionMaker,
                userTypes.DataVerifier,
                userTypes.WasteUser,
              ],
            },
            component: TopFivePollutantPerSector,
            props: {
              name: "Top Five Pollution Per Solid Waste Sector",
              superId: 4,
              hideHeader: false,
            },
          },
          {
            name: "SolidWaste_sw-generation-management",
            path: "reports/sw-generation-management",
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.DecisionMaker,
                userTypes.DataVerifier,
                userTypes.WasteUser,
              ],
            },
            component: SWGenerationManagement,
            props: {
              name: "SolidWaste Management",
              superId: 4,
              hideHeader: false,
            },
          },
          {
            name: "SolidWaste_sw-efficiency",
            path: "reports/sw-efficiency",
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.DecisionMaker,
                userTypes.DataVerifier,
                userTypes.WasteUser,
              ],
            },
            component: SolidWasteEfficiency,
            props: {
              name: "SolidWaste Efficiency",
              superId: 4,
              hideHeader: false,
            },
          },
          {
            name: "SolidWaste_pollution-per-sector",
            path: "reports/pollution-per-sector",
            meta: {
              requiresAuth: true,
              allowedUsers: [userTypes.WasteUser],
            },
            component: PollutionPerSector,
            props: {
              name: "Top Solid Waste Sectors for Pollution",
              superId: 4,
              hideHeader: false,
            },
          },
          {
            name: "SolidWaste_pollution-per-facility",
            path: "reports/pollution-per-facility",
            meta: {
              requiresAuth: true,
              allowedUsers: [userTypes.WasteUser],
            },
            component: PollutionPerFacility,
            props: {
              name: "Top Solid Waste Facilities for Pollution",
              superId: 4,
              hideHeader: false,
            },
          },
          {
            name: "SolidWaste_factories-per-sector",
            path: "reports/factories-per-sector",
            meta: {
              requiresAuth: true,
              allowedUsers: [userTypes.WasteUser],
            },
            component: FactoriesPerSector,
            props: {
              name: "Factories Per Solid Waste Sector",
              superId: 4,
              hideHeader: false,
            },
          },
          {
            name: "SolidWaste_CompostingImportFacilities",
            path: "import/composting",
            component: ImportPage,
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.Admin,
                userTypes.WasteUser,
              ],
            },
            props: {
              type: "Facilities",
              superId: 4,
              template: "CompostingData.xlsx",
              label: "Upload Composting Facilities",
            },
          },
          {
            name: "SolidWaste_LandfillsImportFacilities",
            path: "import/landfills",
            component: ImportPage,
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.Admin,
                userTypes.WasteUser,
              ],
            },
            props: {
              type: "Facilities",
              superId: 4,
              template: "LandfillsData.xlsx",
              label: "Upload Landfills and DumpSites",
            },
          },
          {
            name: "SolidWaste_ImportFile",
            path: "import/annualData",
            component: ImportPage,
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.Admin,
                userTypes.WasteUser,
              ],
            },
            props: {
              type: "SectorAndData",
              superId: 4,
              template: "SolidWasteMiscData.xlsx",
              label: "Annual Data",
            },
          },

          {
            name: "SolidWaste_ImportLookup",
            path: "import/Lookup",
            component: ImportPage,
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.Admin,
                userTypes.WasteUser,
              ],
            },
            props: {
              type: "solidWasteFileLookup",
              superId: 4,
              template: "SolidWasteLookupTables.xlsx",
              label: "lookup data",
            },
          },
          {
            name: "SolidWaste_Requests",
            path: "import/Requests",
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.Admin,
                userTypes.WasteUser,
              ],
            },
            component: AgricultureRequests,
            props: true,
          },
          {
            name: "SolidWaste_chat",
            path: "import/chat",
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.Admin,
                userTypes.WasteUser,
              ],
            },
            component: ChatPage,
          },
          {
            name: "SolidWaste_MapAllOptions",
            path: "map/solidWaste",
            meta: {
              requiresAuth: true,
              allowedUsers: [
                userTypes.Super,
                userTypes.DecisionMaker,
                userTypes.DataCollector,
                userTypes.DataVerifier,
                userTypes.WaterUser,
                userTypes.SpecializedDecisionMaker,
                userTypes.WasteUser,
              ],
            },
            component: MapView,
            props: { superSectorName: "Solid Waste" },
          },
        ],
      },
    ],
  },
];
