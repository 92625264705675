import { createApp,defineAsyncComponent } from 'vue'
import App from './app.vue'
import { router } from "./router";
import { store } from "./store";
import api from './api.js'
import apiUpload from './apiUpload.js';

//import VueAxios from 'vue-axios'
import i18n from '../../i18n'
import VueCookies from 'vue3-cookies'
//import "./bootstrap.js";
//import GoTop from '@inotom/vue-go-top';
import Toast, { useToast } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import VueSimpleAlert from "vue3-simple-alert-next";
import '../css/font-awesome/fontawesome.css';
// import'../'
import '../css/font-awesome/solid.css';
import '../css/font-awesome/brands.css';
//axios.defaults.baseURL = 'http://127.0.0.1:3030'

//i18n globally: https://stackoverflow.com/questions/66732739/internationalization-for-vue-3-vite-with-i18n

window.axios = api
window.axiosUpload = apiUpload;
axios.interceptors.response.use(response => {
    if(response.headers['content-type'].startsWith('application/json')) {
      return response;
    } else {
      //store.dispatch("logout");
      //router.push("/login");
      //window.location.replace("/login");
      return Promise.reject(response);
    }
  }, error => {
    if (error.response.status === 401) {
      store.dispatch("logout");
      router.push("/login");
    }
    return Promise.reject(error);
   });



const app = createApp(App).use(i18n)

app.config.errorHandler = function (err, vm, info) {
  console.log("caught");
  console.error(err, vm, info);
}

window.onerror = function(message, source, line, column, error) {
 console.log("caught");
}

app.config.globalProperties.$datasetColor = [ 
    "blue", 
    "#eaaa00",
     "green", 
     "red", 
     "indigo",
     "#85603F",
    "#99154e",
    "#A03C78",
    "#ce7e00",
    "#5C4D4D",
    "#abce30",
    "#1e3163",
    "#ffd043",
    "#00d982",
    "#0f3053",
    "#1102ab",
    "#361d32",
    "#dec6b6",
    "#df5286",
    "#091a98",
    "#760091",
    "#ae0082",
    "#d6006d",
    "#f20f56",
    "#ff7c23",
    "#ffa600",
    "#D8CBC7",
    "#CC3F0C",
    "#9A6D38",
    "#18314F",
    "#0D0630",

];

//app.config.globalProperties.$toast = useToast();

app.use(VueCookies);
//app.use(VueAxios, axios)
app.use(require('vue-shortkey'));
//app.use(GoTop);
app.use(Toast);
app.use(store)
app.use(router).use(VueSimpleAlert)


//.use(defineAsyncComponent)
router.isReady().then(()=>app.mount('#app'))
export {app};


//Fontawesome: https://stackoverflow.com/questions/66389974/using-font-awesome-in-vue-3