<template>
  <div>
    <div v-if="!this.hideHeader">
      <nav class="breadcrumb" aria-label="breadcrumbs" style="margin-bottom: auto;margin-inline-start: 1em;">
        <ul>
          <li> <router-link :to="{ name: 'AdminHome' }">{{ $t("Home") }}</router-link></li>
          <li> <router-link :to="{ name: 'ReportsTransition' }">{{ $t("Reports") }}</router-link></li>
          <li class="is-active"> <a href="#" aria-current="page">{{ $t(this.name) }}</a> </li>
        </ul>
      </nav>
      <hr style="width:30%;border-width: medium;border-style: groove;margin: 0.5em 0;" />
    </div>


    <div class="columns small-section">

      <div class="column  is-one-third ">


        <div class="box has-light-shadow-border" style="margin-top:0">
          <div class="field is-vertical">
            <city-select v-model="city" :horiz="true" :multiselect="false"></city-select>

          </div>
        </div>
        <div class="box has-light-shadow-border">
          <div class="field is-vertical">
            <years-select v-model="year" :from="new Date().getFullYear() - 100" :label="true" :isRemoveAllYear="true"
              @input="clearData"></years-select>
          </div>
        </div>



        <div class="field is-grouped" style="margin-inline-start:1em;">
          <div class="control alignmentFix">
            <button :class="[{ 'is-loading': loading }, 'button', 'is-medium', 'is-focused', 'is-info']"
              v-shortkey.once="['enter']" @shortkey="loadData()" @click="loadData">{{ $t("Refresh") }}</button>
          </div>

        </div>
      </div>


      <div style="margin: 5px" class="tile Chart">
        <div class="card tile is-child">
          <div class="card-content">
            <chart style="background-color: white; width: 100%" ref="chart_1" id="chart_1" :type="type"
              :data="dataCollection" :bar_options="dataCollectionOptions" :disableClick="true"></chart>

          </div>
        </div>
      </div>
    </div>
  </div>





</template>

<script>
import { defineAsyncComponent } from 'vue'

const YearsSelect = defineAsyncComponent(() => import('../controls/YearsSelect.vue'))
const CitySelect = defineAsyncComponent(() =>
  import("../controls/CitySelect.vue")
);
const Chart = defineAsyncComponent(() => import("../controls/Chart"));

import { useToast } from "vue-toastification";
import {
  initDataCollection,
  initDataOptions,
} from "../reports/reportUtil.js";

export default {
  setup() {
    const toast = useToast();
    return { toast } // Make it available inside methods
  },
  props: ["name", "superId", "hideHeader"],
  data() {
    return {
      header: [{ id: "sector", text: "Sector", sortDir: 0 }],
      pollutantsNames: [],
      dataCollection: initDataCollection(),
      dataCollectionOptions: initDataOptions(
        this.$t("SolidWaste Management"),
        this.$t("ton/year"),
        this.clickHandler
      ),
      Sum: "",
      pollutant: null,
      loading: false,
      year: null,
      toYear: -1,
      error_loading_data: false,
      city: null,
      type: "bar",
    };
  },
  created() {
    // console.log("TopFivePollutantPerSector created");
  },
  watch: {
    city: function () {
      this.clearData();
    },
    department: function () {
      this.clearData();
    },
    superId: function () {
      this.loadData();
    }
  },
  methods: {

    clearData() {
      this.dataCollection = initDataCollection();
    },
    loadData() {

      this.loading = true;
      this.error_loading_data = false;
      const params = {
        dYear: this.year,
        govID: this.city ? this.city.id : null
      }

      //this.requestString = 'report/TopPollutantsPerSector';
      axios
        .post("/report/SWGenerationManagement", params)
        .then(response => {

          var datasetCollection = initDataCollection();
          datasetCollection.datasets[0].data = response.data.Data.data;
          datasetCollection.datasets[0].backgroundColor = this.$datasetColor;
          datasetCollection.labels = response.data.Data.series;

          this.dataCollection = datasetCollection;
        })
        .catch(e => {
          this.toast.error(this.$t("Failed to load data. Try again later."), {
            timeout: 4500,
            hideProgressBar: true,
            rtl: document.direction == "rtl"
          });
          this.error_loading_data = true;
        });
      this.loading = false;
    },
    direction() {
      var lang = this.$i18n.locale;
      if (lang == "ar") return "RTL";
      else return "LTR";
    }
  },
  components: {
    "years-select": YearsSelect,
    "city-select": CitySelect,
    chart: Chart,
  }
};
</script>

<style></style>
